import { useEffect } from "react";
import { Link } from "wouter";
import logo from "../src/assets/images/landing/Logoayuntamiento.png";
import "./styles.css";
import HeaderCookiesPrivacy from "./HeaderCookiesPrivacy";
const BasesEnglish = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <HeaderCookiesPrivacy />
      <div className="px-4 py-8">
        <h1 className=" uppercase text-xl font-latoBold">
          RULES OF CONTEST ‘#VenAlaOfrenda
        </h1>
        <h2 className="mt-6 mb-2 font-latoBold">Organizing Company</h2>
        <div className="font-latoNormal text-sm">
          <p>
            The Sociedad Municipal Zaragoza Cultural SAU (hereinafter ZC) with
            registered office, for the purpose of for the purposes of
            notifications, summons and summons in Zaragoza, at Torreón Fortea,
            calle de Torrenueva 25 with Tax Identification Number A-50377787,
            and Zaragoza Turismo (hereinafter ZT), organize the CONTEST called
            <b> #VenAlaOfrenda</b>.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Dissociation with respect to Twitter
          </h2>
          <p>
            Facebook does not sponsor, endorse or administer this promotion in
            any way, nor is it associated with it. The user disassociates
            himself/herself completely from Twitter and is aware that he/she is
            providing your ZC and ZT information, and not to Twitter. The
            information you provide will be used to manage the contestant's
            participation and to communicate the prize to the contestant
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Object and mechanics of the contest
          </h2>
          <p>
            The contest will be open to users who make the virtual flower
            offering on the website{" "}
            <a href="www.ofrendadeflores.com" className="underline">
              {" "}
              www.ofrendadeflores.com{" "}
            </a>
            , from a location that is outside the national territory of Spain.
            To participate in the draw it is necessary to publish a tweet with
            the <b>unique code</b> obtained after making the offering, including
            the hashtag <b>#VenAlaOfrenda.</b>
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Requirements to participate
          </h2>
          <p>
            The <b> #VenAlaOfrenda</b> contest is open to all individuals over
            18 years of age who do not live in Spain. Persons related to the
            organization of this contest are not eligible to participate. Those
            who do not comply with the requirements will be excluded from
            participating in the contest, or with the mechanics or deadline for
            submission to it.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Selection of the winner, alternates and communication
          </h2>
          <p>
            Among the participants, a list of accounts that have tweeted valid
            codes generated in{" "}
            <a href="www.ofrendadeflores.com">www.ofrendadeflores.com</a> is
            extracted. Only one entry with a valid code for each Twitter account
            will be valid. All those tweets that contain a valid code previously
            included in another tweet will be discarded. A drawing will be
            carried out by means of a computer application among all the users
            who participate. There will be only one winner. The name of the
            winner will be published on the official twitter account of Fiestas
            del Pilar on October 18. The winner gives up the rights to be
            photographed at the moment of the the moment of the delivery of the
            prize or during his visit, and for these photographs to be used by
            Zaragoza Cultura and Zaragoza Turismo.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Prizes</h2>
          <p>
            The contest prize consists of a trip for two people to visit
            Zaragoza during the Ofrenda de Flores 2023. This trip will include
            transportation and lodging for these two people for an amount not
            exceeding 3,000 €.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Reservations and limitations
          </h2>
          <p>
            ZC and ZT is exempt from any responsibility in the event of any
            error in the data provided by the participants that would prevent
            their identification. ZC and ZT reserves the right to postpone,
            cancel or extend the contest period, as well as the right to
            interpret these legal rules. Likewise, ZC and ZT shall be exempt
            from any liability in the event of any of the aforementioned cases,
            as well as from any liability for damages that may be caused during
            the contest may occur during the enjoyment of the prize
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Data protection</h2>
          <p>
            The data provided by the participants will be treated
            confidentially. Zaragoza Cultural guarantees compliance with the
            provisions of REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT
            AND OF THE COUNCIL of 27 April 2016 on the protection of individuals
            with regard to the processing of personal data. natural persons with
            regard to the processing of personal data and the free movement of
            such data (hereinafter referred to as GDPR), we hereby inform users
            about our Data Protection Policy, in particular with regard to the
            exercise of the rights of information, access, rectification,
            opposition and cancellation of personal data of contestants.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Responsible for the treatment
          </h2>
          <p>
            Sociedad Municipal Zaragoza Cultural, S.A.U. <br /> CIF: A50377787
            <br />
            Address: C/ Torrenueva 25, 2ª planta - 50003 Zaragoza <br />
            E-mail: fiestasdelpilar@zaragozacultural.com <br /> Contact DPO:
            dpo@zaragozacultural.com
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Purpose of processing</h2>
          <p>
            participation in the contest. The name of the winner will be made
            public in the official twitter account of Fiestas del Pilar
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Legal basis of the treatment
          </h2>
          <p>
            Consent given when participating in the contest. Article 6.1.a) of
            the RGPD.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Data retention period</h2>
          <p>
            Data will be kept for the years necessary to comply with the legal
            obligations arising from the process in which you are participating.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Recipients</h2>
          <p>
            The personal data will not be transferred to any third party, unless
            the communication has the fulfillment of the purposes directly
            related to the legitimate functions of the transferor and the
            transferee, as well as to the the assignor and the assignee, or the
            Holder has given his consent for such purposes or when the
            assignment is authorized by any law. The result of the contest will
            be published in accordance with the provisions of these rules.
            present terms and conditions.
          </p>{" "}
          <h2 className="mt-6 mb-2 font-latoBold">Rights</h2>
          <p>
            You are entitled to the rights of access, rectification, opposition,
            portability, suppression and limitation of the processing of your
            personal data, which may be exercised by writing to the
            Communication and Sponsorship Unit of the Municipal Zaragoza
            Cultural (located at Torreón Fortea, C/ Torrenueva, 25, Zaragoza).
            You may also file a complaint with the Spanish Data Protection
            Agency in the event that you consider that you have not Data
            Protection Agency in the event that you consider that your data is
            not being adequately processed. More information about data
            protection at:
            https://www.zaragoza.es/sede/portal/politica-privacidad
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Acceptance of the rules</h2>
          <p>
            The simple participation in the contest implies the acceptance of
            these Rules and Regulations, so that the acceptance of all or part
            of them will imply the exclusion of the participant and, as a
            consequence, Zaragoza Cultural will be released. The exclusion of
            the participant and as a consequence of this, Zaragoza Cultural will
            be released from the obligation from complying with the obligation
            contracted with the participant.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BasesEnglish;
