export enum APP_ROUTES {
  HOME = "/",
  EXPRESS = "/express",
  COOKIES = "/cookies",
  PRIVACIDAD = "/privacidad",
  BASES = "/bases",
  PC = "/pc",
  PC_AR = "/pcAr",
}

export const AR_BASE_URL: string = "https://rutafloral.ofrendadeflores.com/";
// export const AR_BASE_URL: string =
//   "https://imasconoar.staging.8thwall.app/advancedar/";

export enum NAME_COOKIES {
  CONSENT = "cookie_consent",
  ANALYTICS = "mtm_cookie_consent",
  PREFERENCES = "pf_cookie_consent",
  LANGUAGE = "cookie_language",
}

export enum MATOMO_ANALYTICS_CATEGORY {
  LANDING2D = "Landing 2d",
}

export enum APP_SCREENS {
  SCREEN1 = "Landing normal",
  SCREEN1_EXPRESS = "Landing express",
  SCREEN2 = "Preparación ofrenda",
  SCREEN3 = "Colocación ramo",
  SCREEN4 = "Resumen ofrenda",
  SCREEN5 = "Concurso twitter",
}

export enum MATOMO_ANALYTICS_ACTION {
  ACCESO_LANDING = "Acceso landing completa",
  ACCESO_EXPRESS = "Acceso landing express",

  /*SCREEN 1 ACTIONS*/
  AR_BTN = "Acceso a AR",
  EXPRESS_BTN = "Acceso a Express",
  VIRTUAL_BTN = "Acceso a Virtual",

  ACCESO_3D = "Acceso Pilar 3D",
  ACCESO_SCREEN2 = "Acceso pantalla Preparación Ofrenda",
  DESCARGA_AGENDA = "Descarga agenda cultural",
  ACCESO_BOLETIN = "Acceso boletín",
  CAMBIO_IDIOMA = "Cambio idioma",

  /**SCREEN 2 ACTIONS */
  ACCESO_OFRENDA = "Acceso pantalla ofrenda",
  CAMBIAR_RAMO = "Ramo cambiado",
  CAMBIAR_GRUPO = "Grupo cambiado",
  CAMBIAR_MENSAJE = "Mensaje cambiado",
  /**SCREEN 3 ACTIONS */
  ELEGIR_NIVEL = "Nivel elegido",
  NIVEL_ALEATORIO = "Nivel aleatorio",
  OFRENDA_REALIZADA = "Ofrenda realizada",

  /**SCREEN 4 ACTIONS  */
  ACCESO_3D_AFTER = "Acceso Pilar 3D tras ofrenda",
  OFRENDA_COMPARTIDA = "Ofrenda compartida whatsapp",
  OFRENDA_GUARDADA = "Ofrenda guardada",
  ACCESO_CONCURSO_TURISMO = "Acceso al concurso de turismo",

  /**SCREEN5 ACTIONS */
  CODIGO_CONCURSO_COPIADO = "Código del concurso copiado",
  CODIGO_CONCURSO_COMPARTIDO = "Código del concurso compartido",
}

export const isIPhone = () => {
  return navigator.userAgent.toLowerCase().indexOf("iphone") !== -1;
};
