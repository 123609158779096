import { useState } from "react";
import { useTranslation } from "react-i18next";
import CookiesAdvancedOptions from "./CookiesAdvancedOptions";
import Cookies from "js-cookie";
import { activateCookies } from "./utils/matomoUtils";
import CookiesStore from "./store/cookiesStore";
import { APP_ROUTES, isIPhone, NAME_COOKIES } from "./utils/utils";
import { Link } from "wouter";

const CookiesBanner = () => {
  const { t, i18n } = useTranslation();
  const setCookies = CookiesStore((state) => state.setCookies);
  const [openAdvancedOptions, setOpenAdvancedOptions] =
    useState<boolean>(false);
  const [analyticsSelected, setAnalyticsSelected] = useState<boolean>(false);
  const [preferencesSelected, setPreferencesSelected] =
    useState<boolean>(false);
  const saveCookies = (all: boolean = false) => {
    Cookies.set(NAME_COOKIES.CONSENT, "true", { path: "/", expires: 365 });

    if (all || analyticsSelected) {
      //Cookies.set(NAME_COOKIES.ANALYTICS, "true", {path: "/", expires: 365});
      activateCookies();
    }

    // if (all || preferencesSelected) {
    //   Cookies.set(NAME_COOKIES.PREFERENCES, "true", {
    //     path: "/",
    //     expires: 365,
    //   });
    //   Cookies.set(NAME_COOKIES.LANGUAGE, i18n.language, {
    //     path: "/",
    //     expires: 365,
    //   });
    // }

    setCookies(
      true,
      all ? true : analyticsSelected
      //all ? true : preferencesSelected
    );
  };

  return (
    <div className="absolute top-0 bg-black bg-opacity-60 w-full min-h-screen flex items-end justify-end z-50 font-gatterSansRegular">
      <div className="w-full bg-cookiesBlue px-8 py-4 text-white text-sm">
        <div>
          <h2 className="text-base font-bold mb-4">{t("cookies-title")}</h2>
          <p>
            {t("cookies-message")}
            <br />
            <Link
              to={APP_ROUTES.COOKIES}
              className="underline font-bold leading-8"
            >
              {" "}
              {t("cookies-policy")}
            </Link>
          </p>
        </div>{" "}
        {openAdvancedOptions && (
          <CookiesAdvancedOptions
            preferencesSelected={preferencesSelected}
            analyticsSelected={analyticsSelected}
            setAnalyticsSelected={(value) => setAnalyticsSelected(value)}
            setPreferencesSelected={(value) => setPreferencesSelected(value)}
          />
        )}
        <div
          className="flex md:justify-end pt-4 pb-16 md:pb-2"
          style={{ paddingBottom: isIPhone() ? 120 : "" }}
        >
          {!openAdvancedOptions ? (
            <div className="flex items-center">
              <button
                onClick={() => setOpenAdvancedOptions(true)}
                className="py-2 font-light underline text-xs flex items-center h-full"
              >
                {t("advanced-configuration")}
              </button>
            </div>
          ) : (
            <button
              onClick={() => saveCookies()}
              className="py-2 font-light underline text-xs flex items-center h-full"
            >
              {t("save-cookies-configuration")}
            </button>
          )}

          <div className="ml-auto md:ml-6">
            <button
              onClick={() => saveCookies(true)}
              className="rounded px-6 py-2 bg-white text-cookiesBlue font-bold"
            >
              {t("accept-all-cookies")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesBanner;
