import { useTranslation } from "react-i18next";
import "./styles.css";
import i18next from "i18next";
import { useEffect, useState } from "react";
import logo from "../src/assets/images/landing/Logoayuntamiento.png";
import { BrowserView, MobileView } from "react-device-detect";
import OfrendaExpress from "./OfrendaExpress";
import { isMobile } from "react-device-detect";
import rotate from "../src/assets/images/landing2023/rotateDevice.png";
import logofiestas from "../src/assets/images/landing2023/logoFiestasPilar.png";
import flowerlanding from "../src/assets/images/landing2023/flowerLanding.png";
import efectosanimados from "../src/assets/images/landing2023/efectosAnimados2.jpg";
import ofrendavirtual from "../src/assets/images/landing2023/ofrendaVirtual.jpg";
import plazapilar from "../src/assets/images/landing2023/plazaPilar3d.jpg";
import tiktok from "../src/assets/images/landing2023/tiktokLogo.png";
import youtube from "../src/assets/images/landing2023/youtubeLogo.png";
import instagram from "../src/assets/images/landing2023/instagramLogo.png";
import twitter from "../src/assets/images/landing2023/twitterLogo.png";
import facebook from "../src/assets/images/landing2023/facebookLogo.png";
import notepierdasnada from "../src/assets/images/landing2023/noTePierdasNada.png";
import footer from "../src/assets/images/landing2023/footerLanding.jpg";
import arrowIcon from "../src/assets/images/landing2023/arrowIcon.png";
import downloadIcon from "../src/assets/images/landing2023/downloadIcon.png";
import warningIcon from "../src/assets/images/landing2023/warningIcon.png";
import { AR_BASE_URL } from "./utils/utils";
import useStore from "./store/store";
import { motion } from "framer-motion";
import useTimer from "./useTimer";
import { Link, useLocation } from "wouter";
import CookiesStore from "./store/cookiesStore";
import i18n from "./i18n";
import { sendMatomoEvent } from "./utils/matomoUtils";
import {
  APP_ROUTES,
  APP_SCREENS,
  MATOMO_ANALYTICS_ACTION,
  MATOMO_ANALYTICS_CATEGORY,
} from "./utils/utils";
import { type } from "os";
import { borderTopWidth } from "html2canvas/dist/types/css/property-descriptors/border-width";
import Orientation from "./Orientation";

interface ILandingProps {
  express: boolean;
  embededVersion: boolean;
}

const LandingVirtualSpace = ({ ...props }: ILandingProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const cookies = CookiesStore((state) => state.cookies);
  const { time, ended } = useTimer(new Date("Oct 8, 2022 9:00:00"));
  const [language, setlanguage] = useState(i18n.language);
  const [ofrendaExpress, setofrendaExpress] = useState(false);
  const myIP = useStore((state) => state.myIP);
  const setMyIP = useStore((state) => state.setMyIP);
  const setlocationSpain = useStore((state) => state.setlocationSpain);
  const [orientacion, setorientacion] = useState("portrait-primary");

  window.addEventListener(
    "orientationchange",
    () => {
      // window.orientation == 90 || -90 -> LANDSCAPE /
      // window.orientation == 0 -> PORTRAIT
      //PORTRAIT

      if (isMobile) {
        if (window.orientation === 0) {
          setorientacion("portrait-primary");
        }
        //LANDSCAPE
        else {
          setorientacion("landscape-primary");
        }
      }
    },
    false
  );

  window.addEventListener("touchstart", function (e) {
    if (e.touches.length > 1 && window.orientation !== 0) {
      setorientacion("landscape-primary");
    }
  });
  useEffect(() => {
    setofrendaExpress(props.express);
    if (props.express) {
      sendMatomoEvent(
        MATOMO_ANALYTICS_CATEGORY.LANDING2D,
        MATOMO_ANALYTICS_ACTION.ACCESO_EXPRESS
      );
    } else {
      sendMatomoEvent(
        MATOMO_ANALYTICS_CATEGORY.LANDING2D,
        MATOMO_ANALYTICS_ACTION.ACCESO_LANDING
      );
    }
    if (isMobile && window.orientation !== 0) {
      setorientacion("landscape-primary");
    }
  }, []);

  useEffect(() => {
    var lang = new URLSearchParams(window.location.search).get("lang");
    if (lang) {
      setlanguage(lang);
    } else {
      lang = "es";
    }
    i18next.changeLanguage(lang);
  }, []);

  // Obtener mi ip

  useEffect(() => {
    // fetch("https://api.ipify.org").then((response) =>
    //   response.text().then((ip) => {
    //     setMyIP(ip);
    //     spanishLocation(ip);
    //   })
    // );
    // // Registrar la visita
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // var raw = JSON.stringify({
    //   country_ip: myIP,
    //   view_type: "landing",
    //   avatar: 1,
    // });
    // fetch("https://share.ofrendadeflores.com/increase/views", {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // });
  }, []);

  async function spanishLocation(myIP: string) {
    var s = await fetch(
      "https://share.ofrendadeflores.com/whatIsMyCountry/" + myIP,
      {
        method: "GET",
      }
    );

    var final = await s.json();
    if (s.ok && final.country !== "Spain") {
      setlocationSpain(false);
    }
  }
  return (
    <>
      {orientacion === "portrait-primary" ? (
        <>
          {!ofrendaExpress ? (
            <div
              id="htmlCode"
              className="font-[Montserrat] absolute w-full z-10 bg-[#F2F0E9] h-full overflowScroll"
            >
              <div
                id="landscapeWarning"
                className="hidden absolute w-full h-full z-[100] overflow-hidden bg-[#F2F0E9]"
              >
                <div className="w-full h-full flex flex-col justify-center items-center text-[#233348]">
                  <img className="w-32 lg:w-48 mb-4 lg:mb-14" src={rotate} />
                  <div className="text-base lg:text-2xl font-[MontserratBold] text-center lg:mb-4">
                    Pon tu móvil en vertical para seguir disfrutando de la
                    experiencia
                  </div>
                  <div className="text-base lg:text-2xl text-center">
                    Turn your cell phone upright to continue enjoying the
                    experience
                  </div>
                </div>
              </div>
              <div className="bg-white h-[4rem] sm:h-[6rem]">
                <div className="flex h-full flex-row justify-between items-center py-1 sm:py-3 px-4 sm:px-8">
                  <img
                    src={logofiestas}
                    className="w-24 sm:w-32"
                    alt="Imagen"
                  />
                  <div
                    onClick={() => {
                      const l = i18n.language.startsWith("es") ? "en" : "es";
                      i18n.changeLanguage(l).then(() => {
                        setlanguage(l);
                        i18next.changeLanguage(l);
                        sendMatomoEvent(
                          MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                          `${
                            location[0] === APP_ROUTES.EXPRESS
                              ? APP_SCREENS.SCREEN1_EXPRESS
                              : APP_SCREENS.SCREEN1
                          }: ${MATOMO_ANALYTICS_ACTION.CAMBIO_IDIOMA}`
                        );
                      });
                    }}
                    className="px-5 font-latoBold w-20 text-center items-center h-[80%] text-[#9A2C6A] border-[#9A2C6A] border-2 rounded-full text-lg md:text-xl lg:text-2xl"
                  >
                    <button className="flex w-full h-full justify-center items-center pt-1">
                      {i18n.language.startsWith("es") ? "ESP" : "EN"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="absolute mt-[1rem] right-3 sm:mt-[2rem] sm:right-5 ">
                <img
                  src={flowerlanding}
                  className="w-16 sm:w-20"
                  alt="Imagen"
                />
              </div>

              <div className="w-full flex flex-col justify-center items-center bg-[#F2F0E9]">
                <div className="w-11/12 sm:w-3/4 flex flex-col justify-center items-center mt-10 sm:mt-4 space-y-8 sm:space-y-10 pb-8 sm:pb-10">
                  <div className="w-full text-4xl text-[#233348] font-[MontserratBold] block sm:hidden">
                    <span>{t("LANDING_TITLE_1")}</span>
                    <br />
                    <span>{t("LANDING_TITLE_2")}</span>
                    <br />
                    <span className="text-[#9A2C6A]">
                      {t("LANDING_TITLE_3")}
                    </span>
                    .
                  </div>
                  <div className="w-full text-4xl text-[#233348] font-[MontserratBold] hidden sm:block">
                    <span>{t("LANDING_TITLE_TABLET_1")}</span>
                    <br />
                    <span className="text-[#9A2C6A]">
                      {t("LANDING_TITLE_TABLET_2")}
                    </span>
                    .
                  </div>
                  <div className="space-y-8 sm:space-y-12">
                    <button
                      onClick={() => {
                        sendMatomoEvent(
                          MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                          MATOMO_ANALYTICS_ACTION.EXPRESS_BTN
                        );
                        window.open("/express", "_self");
                      }}
                      id="ofrendaVirtual2dButton"
                      className="w-full bg-white flex flex-col justify-content items-center rounded-2xl drop-shadow-md"
                    >
                      <img
                        src={ofrendavirtual}
                        className="w-full rounded-t-2xl"
                        alt="Imagen"
                      />
                      <div className="w-[90%] flex flex-col bg-white rounded-b-2xl py-4 sm:py-4 text-left">
                        <div className="text-sm sm:text-lg text-[#233348]">
                          {t("EXPRESS_1")}
                        </div>
                        <div className="text-xl sm:text-2xl font-[MontserratBold] flex flex-row">
                          <div className="text-[#233348]">{t("EXPRESS_2")}</div>
                        </div>
                        <div className="text-sm sm:text-lg text-[#233348] pt-2">
                          {t("EXPRESS_3")}
                        </div>
                      </div>
                    </button>
                    <div
                      id="ofrendaVirtual2dCloseButton"
                      className="hidden w-full bg-white flex flex-col justify-center items-center rounded-2xl "
                    >
                      <img
                        src={ofrendavirtual}
                        className="w-full rounded-t-2xl"
                        alt="Imagen"
                      />
                      <div className="w-[90%] flex flex-col justify-center items-start bg-white rounded-b-2xl py-4 sm:py-8">
                        <div className="text-xl sm:text-2xl font-[MontserratBold] text-[#233348]">
                          {t("EXPRESS_4")}
                        </div>
                        <div className="text-sm sm:text-lg text-[#63717d] font-[MontserratBold] pb-[0.07rem] text-[#E85896]">
                          {t("EXPRESS_5")}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        sendMatomoEvent(
                          MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                          MATOMO_ANALYTICS_ACTION.VIRTUAL_BTN
                        );
                        window.open("/virtual", "_self");
                      }}
                      id="ofrendaVirtual3dButton"
                      className="bg-white flex flex-col justify-content items-center rounded-2xl drop-shadow-md"
                    >
                      <img
                        src={plazapilar}
                        className="w-full rounded-t-2xl"
                        alt="Imagen"
                      />
                      <div className="w-[90%] flex flex-col bg-white rounded-b-2xl py-4 sm:py-4 text-left">
                        <div className="text-sm sm:text-lg text-[#233348]">
                          {t("PLAZA_PILAR_1")}
                        </div>
                        <div className="text-xl sm:text-2xl font-[MontserratBold] flex flex-row">
                          <div className="text-[#233348]">
                            {t("PLAZA_PILAR_2")}
                          </div>
                        </div>
                        <div className="text-sm sm:text-lg text-[#233348] pt-2">
                          {t("PLAZA_PILAR_3")}
                        </div>
                      </div>
                    </button>
                    <div
                      id="ofrendaVirtual3dCloseButton"
                      className="hidden w-full bg-white flex flex-col justify-center items-center rounded-2xl "
                    >
                      <img
                        src={plazapilar}
                        className="w-full rounded-t-2xl"
                        alt="Imagen"
                      />
                      <div className="w-[90%] flex flex-col justify-center items-start bg-white rounded-b-2xl py-4 sm:py-8">
                        <div className="text-xl sm:text-2xl font-[MontserratBold] text-[#233348]">
                          {t("PLAZA_PILAR_4")}
                        </div>
                        <div className="text-sm sm:text-lg text-[#63717d] font-[MontserratBold] pb-[0.07rem] text-[#E85896]">
                          {t("PLAZA_PILAR_5")}
                        </div>
                      </div>
                    </div>
                    {props.embededVersion ? (
                      <div
                      id="efectosAnimadosButton"
                      className="w-full bg-white flex flex-col justify-content items-center rounded-2xl mt-2 sm:mt-6"
                    >
                      <img
                        src={efectosanimados}
                        className="w-full rounded-t-2xl opacity-40"
                        alt="Imagen"
                      />
                      <div className="w-[90%] flex flex-col bg-white rounded-b-2xl py-4 sm:py-4 text-left">
                        <div className="text-sm sm:text-lg text-[#233348] opacity-40">
                          {t("AR_1")}
                        </div>
                        <div className="text-xl sm:text-2xl font-[MontserratBold] flex flex-row opacity-40">
                          <div>{t("AR_5")}</div>
                          <div className="pl-2 sm:pl-3 text-base sm:text-xl text-[#E85896]">
                            {t("AR_2")}
                          </div>
                        </div>
                        <div className="text-sm sm:text-lg text-[#233348] pt-2 opacity-40">
                          {t("AR_3")}
                        </div>
                        <div className="text-sm sm:text-lg bg-[#f3f0e9] space-x-4 px-4 py-2 mt-2 mb-2 text-left opacity-100">
                          <div
                            id="EA_DESCRIPCION3"
                            className="font-[MontserratBold] text-[#973567]"
                          >
                            {t("AR_4")}
                          </div>
                        </div>
                      </div>
                    </div>
                    ) : (
                      <div
                        id="efectosAnimadosButton"
                        className="w-full bg-white flex flex-col justify-content items-center rounded-2xl mt-2 sm:mt-6"
                      >
                        <img
                          src={efectosanimados}
                          className="w-full rounded-t-2xl opacity-40"
                          alt="Imagen"
                        />
                        <div className="w-[90%] flex flex-col bg-white rounded-b-2xl py-4 sm:py-4 text-left">
                          <div className="text-sm sm:text-lg text-[#233348] opacity-40">
                            {t("AR_1")}
                          </div>
                          <div className="text-xl sm:text-2xl font-[MontserratBold] flex flex-row opacity-40">
                            <div>{t("AR_5")}</div>
                            <div className="pl-2 sm:pl-3 text-base sm:text-xl text-[#E85896]">
                              {t("AR_2")}
                            </div>
                          </div>
                          <div className="text-sm sm:text-lg text-[#233348] pt-2 opacity-40">
                            {t("AR_3")}
                          </div>
                          <div className="text-sm sm:text-lg bg-[#f3f0e9] space-x-4 px-4 py-2 mt-2 mb-2 text-left opacity-100">
                            <div
                              id="EA_DESCRIPCION3"
                              className="font-[MontserratBold] text-[#973567]"
                            >
                              {t("AR_4")}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <hr className="w-full hline1" />
                  <div className="w-full flex flex-col justify-center items-center text-[#9A2C6A] space-y-6 sm:space-y-8">
                    <div className="text-xl sm:text-2xl flex flex-row justify-center items-center space-x-3">
                      <div className="w-1 h-1 bg-[#9A2C6A] rounded-full"></div>
                      <div className="font-[MontserratBold]">
                        {t("BUSCANOS_REDES")}
                      </div>
                      <div className="w-1 h-1 bg-[#9A2C6A] rounded-full"></div>
                    </div>
                    <div className="flex flex-row justify-center items-center space-x-5 sm:space-x-7">
                      <button
                        onClick={() =>
                          window.open(
                            "https://www.tiktok.com/@fiestaspilaroficial?lang=es",
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={tiktok}
                          className="w-12 sm:w-14"
                          alt="Imagen"
                        />
                      </button>
                      <button
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/zcultura/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={instagram}
                          className="w-12 sm:w-14"
                          alt="Imagen"
                        />
                      </button>
                      <button
                        onClick={() =>
                          window.open(
                            "https://www.youtube.com/channel/UCEqVjSU87_mASvoCHzCc2Vg",
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={youtube}
                          className="w-12 sm:w-14"
                          alt="Imagen"
                        />
                      </button>
                      <button
                        onClick={() =>
                          window.open("https://twitter.com/ZCultura", "_blank")
                        }
                      >
                        <img
                          src={twitter}
                          className="w-10 sm:w-12"
                          alt="Imagen"
                        />
                      </button>
                      <button
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/ZgzCultura",
                            "_blank"
                          )
                        }
                      >
                        <img
                          src={facebook}
                          className="w-12 sm:w-14"
                          alt="Imagen"
                        />
                      </button>
                    </div>
                    <div className="flex flex-row justify-center items-center space-x-5 sm:space-x-6 text-xl sm:text-2xl">
                      <div>#PILAR23</div>
                      <div>#RUTAFLORAL</div>
                    </div>
                  </div>
                  <hr className="w-full hline1" />
                  <div className="w-full flex flex-col justify-center items-center text-[#9A2C6A] space-y-6 sm:space-y-8 text-xl sm:text-2xl">
                    <img
                      src={notepierdasnada}
                      className="w-28 sm:w-36"
                      alt="Imagen"
                    />
                    <div className="text-xl sm:text-2xl flex flex-row justify-center items-center space-x-3">
                      <div className="w-1 h-1 bg-[#9A2C6A] rounded-full"></div>
                      <div className="font-[MontserratBold]">
                        {t("NO_TE_PIERDAS")}
                      </div>
                      <div className="w-1 h-1 bg-[#9A2C6A] rounded-full"></div>
                    </div>
                    <button
                      className="w-11/12 sm:w-[60%] flex flex-row justify-center items-center space-x-5 py-3 bg-[#9A2C6A] rounded-full drop-shadow-xl"
                      onClick={() =>
                        window.open(
                          "https://ofrendadeflores.com/assets/ProgramaOficial2023.pdf",
                          "_blank"
                        )
                      }
                    >
                      <div className="text-white">{t("AGENDA_CULTURAL")}</div>
                      <img
                        src={downloadIcon}
                        className="w-7 sm:w-8"
                        alt="Imagen"
                      />
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          "https://zaragozacultura.us12.list-manage.com/subscribe?u=7c1cbbc14346551b9ec59f0d1&id=14056e75f1",
                          "_blank"
                        )
                      }
                      className="w-11/12 sm:w-[60%] flex flex-row justify-center items-center space-x-5 py-3 bg-[#9A2C6A] rounded-full drop-shadow-xl"
                    >
                      <div className="text-white">{t("BOLETIN")}</div>
                      <img
                        src={arrowIcon}
                        className="w-7 sm:w-8"
                        alt="Imagen"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {!props.embededVersion && (
                <div className="bg-white text-sm py-5 sm:py-8 flex justify-center items-center space-x-2 sm:space-x-10">
                  <a
                    href={APP_ROUTES.PRIVACIDAD}
                    className="underline text-[#9A2C6A] text-sm sm:text-base"
                  >
                    {t("POLITICA_PRIVACIDAD")}
                  </a>
                  <div>-</div>
                  <a
                    href={APP_ROUTES.COOKIES}
                    className="underline text-[#9A2C6A] text-sm sm:text-base"
                  >
                    {t("POLITICA_COOKIES")}
                  </a>
                </div>
              )}
              <div>
                <img src={footer} className="w-full" alt="Imagen" />
              </div>
            </div>
          ) : (
            <OfrendaExpress idioma={language} />
          )}
        </>
      ) : (
        <Orientation />
      )}
    </>
  );
};

export default LandingVirtualSpace;
