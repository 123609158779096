import { useState, useLayoutEffect } from "react";
import angelCustodioQR from "./assets/images/arPc/angelCustodio.png";
import baturraQR from "./assets/images/arPc/baturra.png";
import fotografoQR from "./assets/images/arPc/fotografo.png";
import murallaQR from "./assets/images/arPc/muralla.png";
import pastorAguilaQR from "./assets/images/arPc/pastorAguila.png";
import portalOfrendaQR from "./assets/images/arPc/portalOfrenda.png";
import torreQR from "./assets/images/arPc/torre.png";
import paloma from "./assets/images/arPc/paloma.png";
import arrowIcon from "../src/assets/images/landing2023/arrowIcon.png";

import { useTranslation } from "react-i18next";
import { APP_ROUTES } from "./utils/utils";
import i18n from "./i18n";

interface IarProps {
  qr: string;
}

const ArMobile = ({ ...props }: IarProps) => {
  const { t } = useTranslation();
  const [minified, setMinified] = useState<boolean>(false);
  const [size, setSize] = useState<number>(window.innerHeight / 17.5);

  const [qrImage, setQRImage] = useState(``);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setSize(window.innerHeight / 17.5);
    });
    switch (props.qr) {
      case "angelCustodio":
        setQRImage("https://ofrendadeflores.com/assets/angelCustodio.png");
        break;
      case "baturra":
        setQRImage("https://ofrendadeflores.com/assets/baturra.png");
        break;
      case "fotografo":
        setQRImage("https://ofrendadeflores.com/assets/fotografo.png");
        break;
      case "muralla":
        setQRImage("https://ofrendadeflores.com/assets/muralla.png");
        break;
      case "pastorAguila":
        setQRImage("https://ofrendadeflores.com/assets/pastorAguila.png");
        break;
      case "portalOfrenda":
        setQRImage("https://ofrendadeflores.com/assets/portalOfrenda.png");
        break;
      case "torre":
        setQRImage("https://ofrendadeflores.com/assets/torre.png");
        break;
    }
  }, [props]);

  return (
    <div
      id="landingPc"
      className="w-full h-full relative font-[Montserrat] -mt-5"
    >
      <div
        onClick={() => {
          const l = i18n.language.startsWith("es") ? "en" : "es";
          i18n.changeLanguage(l).then(() => {
            // sendMatomoEvent(
            //   MATOMO_ANALYTICS_CATEGORY.LANDING2D,
            //   `${
            //     location[0] === APP_ROUTES.EXPRESS
            //       ? APP_SCREENS.SCREEN1_EXPRESS
            //       : APP_SCREENS.SCREEN1
            //   }: ${MATOMO_ANALYTICS_ACTION.CAMBIO_IDIOMA}`
            // );
          });
        }}
        className="font-latoBold text-center text-lg md:text-xl lg:text-2xl absolute top-2 right-4 border-2 w-20 py-1 px-5 rounded-3xl border-[#9A2C6A] text-[#9A2C6A] mt-6"
      >
        <button id="languageButtonPc" className="mt-1">
          {i18n.language.startsWith("es") ? "ESP" : "EN"}
        </button>
      </div>

      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="w-3/4 flex flex-col justify-center items-center">
          <img src={paloma} className="w-40" alt="Imagen" />
          <div className="font-[MontserratBold] text-2xl text-[#233348] text-center mt-2">
            {t("EXP1")} <br />
            <span className="text-[#9A2C6A]">{t("EXP2")}</span> {t("EXP3")}
            <br />
            {t("EXP4")}
          </div>
          <div className="text-base text-[#233348] opacity-70 mt-2 text-center">
            {t("EXP5")}
          </div>
          <img src={qrImage} className="w-40 mt-6" alt="Imagen" />
          {/* <hr className="w-full hline1 my-6" />
          <div className="text-base text-[#233348] mb-4">{t("EXP6")}</div>
          <button
            id="ofrenda2dButton"
            className="w-[90%] flex flex-row justify-center items-center space-x-3 space-x-5 py-3 bg-[#9A2C6A] rounded-full drop-shadow-xl mb-3"
          >
            <div className="text-white text-lg">{t("EXP7")}</div>
            <img src={arrowIcon} className="w-7" alt="Imagen" />
          </button>
          <button
            id="ofrenda3dButton"
            className="w-[90%] flex flex-row justify-center items-center space-x-3 space-x-5 py-3 bg-[#9A2C6A] rounded-full drop-shadow-xl"
          >
            <div className="text-white text-lg">{t("EXP8")}</div>
            <img src={arrowIcon} className="w-7" alt="Imagen" />
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ArMobile;
