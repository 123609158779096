import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import logo from "../src/assets/images/logo.png";
import close from "../src/assets/images/close.png";
import bienhecho from "../src/assets/images/landing2023/bgmaskofrenda.png";
import ofrenda from "../src/assets/images/landing2023/ofrenda2023.png";
// import ofrenda from "../src/assets/images/imagenOfrenda.png";

import nube1 from "../src/assets/images/landing2023/nube1.png";
import nube2 from "../src/assets/images/landing2023/nube2.png";
import lines from "../src/assets/images/landing2023/bglines.png";
import guardar from "../src/assets/images/landing2023/guardar.png";
import whatsapp from "../src/assets/images/landing2023/whatsapp.png";
import flecha from "../src/assets/images/flecha.png";
import flechaLateral from "../src/assets/images/flechaLateral.png";
import plus from "../src/assets/images/landing2023/more.png";
import logofiestas from "../src/assets/images/landing2023/logoFiestasPilar.png";
import notepierdasnada from "../src/assets/images/landing2023/noTePierdasNada.png";

import lupa from "../src/assets/images/landing2023/lupa.png";
import imgRamo from "../src/assets/images/landing2023/imgramo.png";
import bgofering from "../src/assets/images/landing2023/bgofering.png";

import lapiz from "../src/assets/images/landing2023/lapiz.png";
import i18next from "i18next";
import R1 from "../src/assets/images/ramos/2023/ramo1.png";
import R2 from "../src/assets/images/ramos/2023/ramo2.png";
import R3 from "../src/assets/images/ramos/2023/ramo3.png";
import R4 from "../src/assets/images/ramos/2023/ramo4.png";
import R5 from "../src/assets/images/ramos/2023/ramo5.png";
import R6 from "../src/assets/images/ramos/2023/ramo6.png";
import R7 from "../src/assets/images/ramos/2023/ramo7.png";
import R8 from "../src/assets/images/ramos/2023/ramo8.png";
import i18n from "./i18n";
import C1 from "../src/assets/images/imagenescentrales/2023/diseno1.jpg";
import C2 from "../src/assets/images/imagenescentrales/2023/diseno2.jpg";
import C3 from "../src/assets/images/imagenescentrales/2023/diseno3.jpg";
import C4 from "../src/assets/images/imagenescentrales/2023/diseno4.jpg";
import C5 from "../src/assets/images/imagenescentrales/2023/diseno5.jpg";
import C6 from "../src/assets/images/imagenescentrales/2023/diseno6.jpg";
import C7 from "../src/assets/images/imagenescentrales/2023/diseno7.jpg";
import C8 from "../src/assets/images/imagenescentrales/2023/diseno8.jpg";
import gruposJSON from "../src/assets/cms/grupos.json";
import flores1 from "../src/assets/images/landing2023/flor.png";
import flores2 from "../src/assets/images/landing2023/flor2.png";
import "./styles.css";
import { motion } from "framer-motion";
import useStore from "./store/store";
import { sendMatomoEvent } from "./utils/matomoUtils";
import { isMobile } from "react-device-detect";
import Orientation from "./Orientation";

import {
  APP_ROUTES,
  APP_SCREENS,
  MATOMO_ANALYTICS_ACTION,
  MATOMO_ANALYTICS_CATEGORY,
} from "./utils/utils";
import { Link, useLocation } from "wouter";

const OfrendaExpress = ({ ...props }) => {
  const [orientacion, setorientacion] = useState("portrait-primary");

  window.addEventListener(
    "orientationchange",
    () => {
      // window.orientation == 90 || -90 -> LANDSCAPE /
      // window.orientation == 0 -> PORTRAIT
      //PORTRAIT
      if (isMobile) {
        if (window.orientation === 0) {
          setorientacion("portrait-primary");
        }
        //LANDSCAPE
        else {
          setorientacion("landscape-primary");
        }
      }
    },
    false
  );
  const DIRECTUS_URL = process.env.REACT_APP_DIRECTUS_URL;
  const { t } = useTranslation();
  const location = useLocation();
  const [buscadorGrupo, setbuscadorGrupo] = useState(false);
  const [cmsGruposLoaded, setcmsGruposLoaded] = useState(false);
  //Escenas
  const [escena, setescena] = useState(0);
  const [alturaOfrendaRamo, setalturaOfrendaRamo] = useState(-1);
  //Ramos
  const [bienhechobool, setbienhecho] = useState(false);
  const [modalInput, setModalInput] = useState(false);
  const [inputbuscadorfocus, setinputbuscadorfocus] = useState(false);
  const [mensajeShare, setmensajeShare] = useState("");
  const [errorPeticion, seterrorPeticion] = useState(false);
  const [idImg, setidImg] = useState("");
  const [ofrendasrealizadas, setmofrendasrealizadas] = useState(0);
  const [groupIndex, setgroupIndex] = useState(0);
  const [totalGroupOfferings, settotalgrupoofrendas] = useState(0);
  const [ramos] = useState([R1, R2, R3, R4, R5, R6, R7, R8]);
  const [imagenesCentrales] = useState([C1, C2, C3, C4, C5, C6, C7, C8]);
  //Grupos
  const [grupos, setgrupos] = useState([]);
  const [filtro, setfiltro] = useState([]);
  const [grupoSelected, setgrupoSelected] = useState("");
  const [grupoSelectedCompleto, setgrupoSelectedCompleto] = useState("");
  const [ramoSelected, setramoSelected] = useState(getRandomIntInclusive(1, 8));
  const [imagenCtx, setimagenCtx] = useState("");
  const [idioma, setiidioma] = useState("");
  const [openModalTwitter, setopenModalTwitter] = useState(false);
  const myIP = useStore((state) => state.myIP);
  const [codeTwitter, setcodeTwitter] = useState("");
  const [textosShare, settextosShare] = useState<any>("");
  const locationSpain = useStore((state) => state.locationSpain);
  const [randomMessage, setrandomMessage] = useState(
    getRandomIntInclusive(0, 13)
  );
  const firstUpdate = useRef(true);
  const aleatorioClicked = useRef(false);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    sendMatomoEvent(
      MATOMO_ANALYTICS_CATEGORY.LANDING2D,
      `${APP_SCREENS.SCREEN2}: ${MATOMO_ANALYTICS_ACTION.CAMBIAR_RAMO}`
    );
  }, [ramoSelected]);

  useEffect(() => {
    if (alturaOfrendaRamo !== -1 && !aleatorioClicked.current) {
      sendMatomoEvent(
        MATOMO_ANALYTICS_CATEGORY.LANDING2D,
        `${APP_SCREENS.SCREEN3}: ${MATOMO_ANALYTICS_ACTION.ELEGIR_NIVEL}`
      );
    }
  }, [alturaOfrendaRamo]);

  useEffect(() => {
    if (isMobile && window.orientation !== 0) {
      setorientacion("landscape-primary");
    }

    i18n.changeLanguage(props.idioma);
    changeText(props.idioma);
  }, []);

  function changeText(language: string) {
    settextosShare(
      language === "es"
        ? [
            "¡Te deseo unas felices fiestas en compañía de familia y amigos!😀😀",
            "¡Viva la Virgen del Pilar! y ¡Viva Aragón! Nos vemos con el cachirulo puesto 🟡🔴🟡🔴",
            "✨🌟 Felices Fiestas 2023 y felicidades a todos los aragoneses 🌟✨",
            "🌹Feliz día a todas las Pilis, Pilares y Pilucas🌹 ¡A celebrarlo en buena compañía!",
            "¡Feliz día del Pilar! Te deseo unas fiestas llenas de alegría y felicidad 🥰",
            "❤️❤️❤️ Felices días a todos los mañicos y mañicas ❤️❤️❤️",
            "¡Es hora de sacar los cachirulos y esquivar a las charangas!🎷🎺🥁🎵¡Felices fiestas!",
            "¡A exprimir las fiestas al máximo! 🥳 Qué alegría poder disfrutarlas otra vez 💃🕺",
            "💐💐💐 ¡Felices fiestas a tod@s l@s zaragozan@s 💐💐💐",
            "¡Felices fiestas del Pilar y feliz día de la Hispanidad! ❤💛💫",
            "P’al Pilar sale lo mejor, los gigantes y la procesión, ¡felices fiestas! 🎶🎉",
            "Muy felices fiestas a tod@s, ¡hala mañ@ a disfrutar! 💃🥳",
            "Estas fiestas, ¡a darlo TODO! 💥💥💥",
            "Pipipilaresss💃",
          ]
        : [
            "I wish you happy festivities in the company of family and friends!😀😀",
            "Hurrah for the Virgen del Pilar and hurrah for Aragon! 🟡🔴🟡🔴",
            "✨🌟 Happy 2023 festivities & congratulations to all Aragonese people 🌟✨",
            "🌹Happy day to all the Pilis, Pilares and Pilucas🌹 Let's celebrate in good company!",
            "Happy Pilar's Day! I wish you a festivity full of joy and happiness 🥰",
            "❤️ Happy days to all ‘mañicos’ and ‘mañicas’ ❤️",
            "It's time to wear the ‘cachirulos’ and enjoy the charangas!🎷🎺🥁🎵 Happy Pilar22!",
            "¡Let's make the most these Pilar’s Days! 🥳 What a joy to enjoy them again 💃 🕺",
            "💐💐💐Happy days to all zaragozan@s!💐💐💐",
            "Happy Pilar festivities and happy Hispanic Day! ❤💛💫",
            "P'al Pilar comes out the best, the giants and the procession, happy days! 🎶🎉",
            "Very happy holidays to all of you, time to enjoy ‘mañ@s’! 💃🥳",
            "These days let’s give it ALL! 💥💥💥",
            "Pipipilaresss💃",
          ]
    );
  }
  // let [textosShare] = useState(
  //   props.idioma === "es"
  //     ?
  // );

  useEffect(() => {
    //i18next.changeLanguage(props.idioma);
    setiidioma(props.idioma);
    downloadGroups();
  }, []);

  useEffect(() => {
    if (!bienhechobool) return;
    sendMatomoEvent(
      MATOMO_ANALYTICS_CATEGORY.LANDING2D,
      `${APP_SCREENS.SCREEN3}: ${MATOMO_ANALYTICS_ACTION.OFRENDA_REALIZADA}`
    );
  }, [bienhechobool]);

  useEffect(() => {
    if (
      !locationSpain &&
      codeTwitter === "" &&
      escena === 2 &&
      openModalTwitter
    ) {
      var call = fetch("https://share.ofrendadeflores.com/code").then(
        (response) =>
          response.text().then((codigo) => {
            var s = JSON.parse(codigo);
            setcodeTwitter(s.code);
          })
      );
    }
  }, [openModalTwitter]);
  //PETICIONES

  async function ofrendaRealizada() {
    window.localStorage.setItem("ofrendarealizada", "1");
    await fetch("https://share.ofrendadeflores.com/increase/offering", {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        group_name:
          grupoSelectedCompleto === "" ? "ALONE" : grupoSelectedCompleto,
        bouquet: ramoSelected - 1,
        country_ip: myIP,
      }),
      method: "POST",
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          // Get Error Message from Body or Default to Response Status
          const error = (data && data.errors) || response.status;
          // Return throwing Error obtained
          return Promise.reject(error);
        }
        setmofrendasrealizadas(data.totalOfferings);
        settotalgrupoofrendas(data.totalGroupOfferings);
        setgroupIndex(data.groupIndex);
      })
      .catch((error) => {
        seterrorPeticion(true);
      });
  }

  function downloadGroups() {
    var gruposLocal = gruposJSON.data;
    gruposLocal.sort(
      //@ts-ignore
      (a, b) => (a.nombre > b.nombre) - (a.nombre < b.nombre)
    );
    //@ts-ignore
    setgrupos(gruposLocal);

    //@ts-ignore
    setfiltro(gruposLocal);
  }

  // async function downloadGroups() {
  //   await fetch(
  //     "https://cms-dev.ofrendadeflores.com/items/grupos?fields=nombre&limit=1000",
  //     {
  //       headers: { "Content-Type": "application/json" },
  //       method: "GET",
  //     }
  //   )
  //     .then(async (response) => {
  //       const data = await response.json();
  //       if (!response.ok) {
  //         // Get Error Message from Body or Default to Response Status
  //         const error = (data && data.errors) || response.status;
  //         // Return throwing Error obtained
  //         return Promise.reject(error);
  //       } data.data.sort(
  //         //@ts-ignore
  //         (a, b) => (a.nombre > b.nombre) - (a.nombre < b.nombre)
  //       );
  //       //@ts-ignore
  //       setgrupos([...grupos, ...data.data]);

  //       //@ts-ignore
  //       setfiltro([...grupos, ...data.data]);
  //       // setElements(elements => [...elements, data]);
  //     })
  //     .finally(() => {
  //       setcmsGruposLoaded(true);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  function generarImagenWhatsapp(imagen: any) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("base64image", imagen);
    // var raw = JSON.stringify({
    //   base64image: imagen,
    // });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    //@ts-ignore
    fetch("https://share.ofrendadeflores.com/save", requestOptions)
      .then((response) => response.json())
      .then((result) => setidImg(result.img))
      .catch((error) => /*console.log("error", error)*/ {});
  }
  /*************************************************/

  async function make_image(ramoseleccionado: number) {
    var myFont = new FontFace("myFont", "url(assets/fonts/latoBold.ttf)");

    var c = document.getElementById("myCanvas");
    if (c) {
      //@ts-ignore
      var ctx = c?.getContext("2d");
      //gradient
      var randomGrandient = getRandomIntInclusive(0, 5);

      var colorsGradient = [
        ["#a5f3fc", "#93c5fd"],
        ["#bbf7d0", "#86efac"],
        ["#fef08a", "#fde047"],
        ["#fecaca", "#fca5a5"],
        ["#c4b5fd", "#ddd6fe"],
      ];
      var gradient = ctx.createLinearGradient(0, 180, 220, 0);
      gradient.addColorStop(0, colorsGradient[ramoSelected - 1][0]);
      gradient.addColorStop(1, colorsGradient[ramoSelected - 1][1]);
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, 330, 330);

      document.fonts.add(myFont);
      //@ts-ignore
      var ctx2 = c?.getContext("2d");
      ctx2.textAlign = "left";
      ctx2.fillStyle = "#ffffff";
      ctx2.font = "40px myFont";

      printAtWordWrap(
        ctx2,
        "¡Viva la virgen del pilar \nSalud para todos",
        20,
        100,
        30,
        200
      );

      var ramoimg = new Image();
      ramoimg.crossOrigin = "anonymous";
      ramoimg.src = ramos[ramoseleccionado];

      ramoimg.onload = function () {
        if (c) {
          //@ts-ignore
          var ctx = c?.getContext("2d");
          var altura = 0;
          if (ramoSelected === 1 || ramoSelected === 2 || ramoSelected === 3) {
            altura = 120;
          } else {
            altura = 70;
          }

          ctx.drawImage(ramoimg, 190, altura, 237 / 1.7, 430 / 1.7);
        }
      };
    }
  }

  function printAtWordWrap(
    context: any,
    text: string,
    x: number,
    y: number,
    lineHeight: number,
    fitWidth: number
  ) {
    fitWidth = fitWidth || 0;

    if (fitWidth <= 0) {
      context.fillText(text, x, y);
      return;
    }
    var words = text.split(" ");
    var currentLine = 0;
    var idx = 1;
    while (words.length > 0 && idx <= words.length) {
      var str = words.slice(0, idx).join(" ");
      var w = context.measureText(str).width;
      if (w > fitWidth) {
        if (idx === 1) {
          idx = 2;
        }
        context.fillText(
          words.slice(0, idx - 1).join(" "),
          x,
          y + lineHeight * currentLine
        );
        currentLine++;
        words = words.splice(idx - 1);
        idx = 1;
      } else {
        idx++;
      }
    }
    if (idx > 0)
      context.fillText(words.join(" "), x, y + lineHeight * currentLine);
  }

  function getRandomIntInclusive(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
  }

  return (
    <>
      {orientacion === "portrait-primary" ? (
        <>
          {escena === 0 && (
            <div className="flex flex-col h-full w-full overflow-hidden bg-[#F2F0E9] font-[Montserrat]">
              <div
                style={{ height: "7%" }}
                className="flex h-full flex-row justify-between items-center pt-8 py-5 px-4 sm:px-8"
              >
                <img src={logofiestas} className="w-24 sm:w-32"></img>
                {/* <div className="pl-4 font-[MontserratBold] text-xl md:text-xl lg:text-2xl">
                  {t("pilar23")}
                </div> */}
              </div>
              {!buscadorGrupo && (
                <div
                  id="selectoridioma"
                  onClick={() => {
                    const l = i18n.language.startsWith("es") ? "en" : "es";
                    i18n.changeLanguage(l).then(() => {
                      changeText(i18n.language);

                      sendMatomoEvent(
                        MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                        `${
                          location[0] === APP_ROUTES.EXPRESS
                            ? APP_SCREENS.SCREEN1_EXPRESS
                            : APP_SCREENS.SCREEN1
                        }: ${MATOMO_ANALYTICS_ACTION.CAMBIO_IDIOMA}`
                      );
                    });
                  }}
                  className="absolute right-[2vh] top-[2%] px-5 h-10 font-latoBold w-20 text-center items-center  text-[#9A2C6A] border-[#9A2C6A] border-2 rounded-full text-lg z-10"
                >
                  <button className="flex w-full h-full justify-center items-center pt-1">
                    {i18n.language.startsWith("es") ? "ESP" : "EN"}
                  </button>
                </div>
              )}

              <div
                style={{ height: "60%" }}
                className=" relative bg-[#F2F0E9] flex justify-center items-center"
              >
                {!buscadorGrupo && (
                  <div
                    onClick={() => {
                      setModalInput(true);
                    }}
                    className="absolute bottom-2 right-2 z-20"
                  >
                    <img className="h-16" src={lapiz}></img>
                  </div>
                )}
                <button
                  className="absolute left-[5vw]"
                  onClick={() => {
                    setrandomMessage(getRandomIntInclusive(0, 13));
                    ramoSelected !== 1
                      ? setramoSelected(ramoSelected - 1)
                      : setramoSelected(8);
                  }}
                >
                  <img className="rotate-180 h-[10vw]" src={flechaLateral} />
                </button>
                <button
                  className="absolute right-[5vw]"
                  onClick={() => {
                    setrandomMessage(getRandomIntInclusive(0, 13));
                    ramoSelected !== 8
                      ? setramoSelected(ramoSelected + 1)
                      : setramoSelected(1);
                  }}
                >
                  <img className=" h-[10vw]" src={flechaLateral} />
                </button>
                <div
                  onClick={() => {
                    setModalInput(true);
                  }}
                  className="relative scale-[85%]"
                  id="canvasOfrenda"
                >
                  {grupoSelected && (
                    <div
                      style={{
                        lineHeight: "3vw",
                        right: "3vw",
                        width: "35vw",
                      }}
                      className="absolute bottom-[8%] text-[1.6vh] color-azul-claro"
                    >
                      {grupoSelected}
                    </div>
                  )}
                  {/* IMAGEN CENTRAL */}
                  <img
                    onClick={() => {
                      setModalInput(true);
                    }}
                    style={{ width: "80vw" }}
                    src={imagenesCentrales[ramoSelected - 1]}
                  />

                  <div
                    id="textTop"
                    style={{
                      lineHeight: "6.1vw",
                      top: "24.2vw",
                      right: "7.2vw",
                      width: "35vw",
                    }}
                    className="absolute flex flex-col color-azul-claro font-latoItalic text-[3vw] w-32 "
                  >
                    <div className="pb-2 whitespace-pre-line">
                      {mensajeShare !== ""
                        ? mensajeShare
                        : textosShare[randomMessage]}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col justify-between bg-white"
                style={{ height: "65%" }}
              >
                <div
                  style={{ borderBottomWidth: "1px" }}
                  className="flex justify-between items-center space-x-3  px-4 overflow-auto"
                >
                  <div
                    onClick={() => {
                      setrandomMessage(getRandomIntInclusive(0, 13));
                      setramoSelected(1);
                    }}
                    className={`snap-center scale-75 h-24 px-6 bg-gradient-to-t from-[#DD777F] to-[rgba(221,119,127,0.4)]  rounded-full ${
                      ramoSelected === 1 &&
                      " h-full my-1 scale-100 px-5 border-solid border-4 border-[#9A2C6A]"
                    }`}
                  >
                    <img className="flex w-12 max-w-xs" src={R1}></img>
                  </div>
                  <div
                    onClick={() => {
                      setrandomMessage(getRandomIntInclusive(0, 13));
                      setramoSelected(2);
                    }}
                    className={`snap-center scale-75 h-24 px-6 bg-gradient-to-t from-[#68a170] to-[rgba(147,158,130,0.4)]  rounded-full ${
                      ramoSelected === 2 &&
                      " h-full my-1 scale-100 px-5 border-solid border-4  border-[#9A2C6A]"
                    }`}
                  >
                    <img className="flex w-12 max-w-xs" src={R2}></img>
                  </div>
                  <div
                    onClick={() => {
                      setrandomMessage(getRandomIntInclusive(0, 13));
                      setramoSelected(3);
                    }}
                    className={`snap-center scale-75 h-24 px-6 bg-gradient-to-t from-[#Efbc87] to-[rgba(239,188,135,0.4)]  rounded-full ${
                      ramoSelected === 3 &&
                      " h-full my-1 scale-100 px-5 border-solid border-4  border-[#9A2C6A]"
                    }`}
                  >
                    <img className="flex w-12 max-w-xs" src={R3}></img>
                  </div>
                  <div
                    onClick={() => {
                      setrandomMessage(getRandomIntInclusive(0, 13));
                      setramoSelected(4);
                    }}
                    className={`snap-center scale-75 h-24 px-6 bg-gradient-to-t from-[#7ab0b4] to-[rgba(102,191,197,0.4)] rounded-full ${
                      ramoSelected === 4 &&
                      " h-full my-1 scale-100 px-5 border-solid border-4  border-[#9A2C6A]"
                    }`}
                  >
                    <img className="flex w-12 max-w-xs" src={R4}></img>
                  </div>
                  <div
                    onClick={() => {
                      setrandomMessage(getRandomIntInclusive(0, 13));
                      setramoSelected(5);
                    }}
                    className={`snap-center scale-75 h-24 px-6 bg-gradient-to-t from-[#e08ba3] to-[rgba(207,138,158,0.4)]  rounded-full ${
                      ramoSelected === 5 &&
                      " h-full my-1 scale-100 px-5 border-solid border-4  border-[#9A2C6A]"
                    }`}
                  >
                    <img className="flex w-12 max-w-xs" src={R5}></img>
                  </div>
                  <div
                    onClick={() => {
                      setrandomMessage(getRandomIntInclusive(0, 13));
                      setramoSelected(6);
                    }}
                    className={`snap-center scale-75 h-24 px-6 bg-gradient-to-t from-[#4D9297] to-[rgba(77,146,151,0.4)]  rounded-full ${
                      ramoSelected === 6 &&
                      " h-full my-1 scale-100 px-5 border-solid border-4  border-[#9A2C6A]"
                    }`}
                  >
                    <img className="flex w-12 max-w-xs" src={R6}></img>
                  </div>
                  <div
                    onClick={() => {
                      setrandomMessage(getRandomIntInclusive(0, 13));
                      setramoSelected(7);
                    }}
                    className={`snap-center scale-75 h-24 px-6 bg-gradient-to-t from-[#cdb6ff] to-[rgba(184,165,224,0.4)]   rounded-full ${
                      ramoSelected === 7 &&
                      " h-full my-1 scale-100 px-5 border-solid border-4  border-[#9A2C6A]"
                    }`}
                  >
                    <img className="flex w-12 max-w-xs" src={R7}></img>
                  </div>
                  <div
                    onClick={() => {
                      setrandomMessage(getRandomIntInclusive(0, 13));
                      setramoSelected(8);
                    }}
                    className={`snap-center scale-75 h-24 px-6 bg-gradient-to-t from-[#EBCB8B] to-[rgba(235,203,139,0.4)]  rounded-full ${
                      ramoSelected === 8 &&
                      " h-full my-1 scale-100 px-5 border-solid border-4  border-[#9A2C6A]"
                    }`}
                  >
                    <img className="flex w-12 max-w-xs" src={R8}></img>
                  </div>
                </div>

                {grupoSelected === "" ? (
                  <div className="flex flex-row items-center gap-4 justify-center ">
                    <div className="flex flex-col items-start font-latoBold space-y-0  font-lato color-azul-oscuro">
                      <div className="flex text-xl md:text-2xl lg:text-3xl">
                        {t("uneteaungrupo")}
                      </div>
                      <div className="flex text-sm ">{t("opcional")}</div>
                    </div>
                    <button
                      onClick={(e) => {
                        var misGrupos = grupos.filter((name: any) =>
                          name["nombre"].startsWith(e.currentTarget.value)
                        );
                        setfiltro(misGrupos);
                        setbuscadorGrupo(true);
                      }}
                      className="flex flex-row rounded-md p-3  items-center bg-white pb-7 text-lg md:text-xl lg:text-2xl font-latoBold"
                    >
                      <img className="h-16" src={plus}></img>
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-row items-center justify-between mx-8 pt-2 border-2 border-[#9A2C6A] rounded-md bg-[#F2F0E9] ">
                    <div className="flex flex-col px-4 pb-3 items-left font-lato text-md md:text-lg lg:text-xl text-[#9A2C6A] ">
                      {/* {t("grupo")} */}
                      <div className="text-[#9A2C6A] text-xl">
                        {" "}
                        {grupoSelected}
                      </div>
                    </div>
                    <div
                      className="flex justify-center items-center h-10 w-8 mb-2 mr-4"
                      onClick={() => {
                        setgrupoSelected("");
                        setgrupoSelectedCompleto("");
                      }}
                    >
                      <img className=" w-10" src={close}></img>
                    </div>
                  </div>
                )}
                <div className="flex  bottom-10 pb-6 mb-2 h-20 w-full justify-center">
                  <button
                    onClick={() => {
                      try {
                        let div = document.getElementById("canvasOfrenda");
                        let top = document.getElementById("textTop");
                        top!.style.top = "22vw";
                        setalturaOfrendaRamo(-1);
                        ofrendaRealizada();
                        //@ts-ignore
                        html2canvas(div, { scale: 2 }).then(function (canvas) {
                          setescena(1);

                          // setTimeout(() => {
                          //   setescena(2);
                          // }, 4000);

                          setimagenCtx(canvas.toDataURL());
                          generarImagenWhatsapp(canvas.toDataURL());
                        });
                        sendMatomoEvent(
                          MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                          `${APP_SCREENS.SCREEN2}: ${MATOMO_ANALYTICS_ACTION.ACCESO_OFRENDA}`
                        );
                      } catch (e) {}
                    }}
                    className="flex px-6 font-latoBold justify-center items-center rounded-full text-white text-lg md:text-xl lg:text-2xl bg-[#9A2C6A] pt-1"
                  >
                    {t("siguiente")}
                    <svg
                      className="ml-4 mb-1"
                      width="32"
                      height="20"
                      viewBox="0 0 25 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 7.125C0.447715 7.125 5.68981e-08 7.57272 0 8.125C-5.68981e-08 8.67728 0.447715 9.125 1 9.125L1 7.125ZM24.3922 8.83211C24.7828 8.44158 24.7828 7.80842 24.3922 7.41789L18.0283 1.05393C17.6377 0.663409 17.0046 0.663409 16.6141 1.05393C16.2235 1.44446 16.2235 2.07762 16.6141 2.46815L22.2709 8.125L16.6141 13.7819C16.2235 14.1724 16.2235 14.8055 16.6141 15.1961C17.0046 15.5866 17.6377 15.5866 18.0283 15.1961L24.3922 8.83211ZM1 9.125L23.6851 9.125L23.6851 7.125L1 7.125L1 9.125Z"
                        fill="#ffffff"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {modalInput && (
                <>
                  <div
                    style={{ background: "rgba(0,0,0,0.8)" }}
                    className="absolute flex w-full  h-full justify-center items-center z-30"
                  >
                    <div className="flex flex-col justify-center bg-white items-center rounded-3xl w-full h-80 mx-6 z-50 text-lg">
                      <div className="flex items-center justify-center pb-2 text-[#9A2C6A] font-latoBold">
                        {t("escribetumensaje")}
                      </div>

                      <textarea
                        style={{ height: "70%" }}
                        maxLength={70}
                        color="#F2F0E9"
                        value={mensajeShare}
                        id="myinputMessage"
                        placeholder={t("escribetumensaje")}
                        className="w-full p-10 color-azul-claro rounded-md font-[Montserrat] text-lg md:text-xl lg:text-2xl bg-[#F2F0E9] text-[#233348]"
                        onChange={(e) => {
                          setmensajeShare(e.currentTarget.value);
                        }}
                      />
                      <div className="flex flex-row justify-between w-full px-10 pt-3">
                        <div
                          onClick={() => {
                            setModalInput(false);
                            setmensajeShare("");
                          }}
                          className="flex text-[#9A2C6A] font-[Montserrat] "
                        >
                          {t("cancelar")}
                        </div>
                        <div
                          onClick={() => {
                            if (mensajeShare !== "") {
                              sendMatomoEvent(
                                MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                                `${APP_SCREENS.SCREEN2}: ${MATOMO_ANALYTICS_ACTION.CAMBIAR_MENSAJE}`
                              );
                            }
                            setModalInput(false);
                          }}
                          className="flex text-[#9A2C6A] font-latoBold"
                        >
                          {t("aceptar")}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* BUSCADOR GRUPO */}
              {buscadorGrupo && (
                <>
                  <div className="absolute flex w-full justify-center items-center h-full bg-white">
                    <button
                      className="absolute h-10 right-2 top-2"
                      onClick={() => {
                        setbuscadorGrupo(false);
                      }}
                    >
                      <img className="h-10" src={close} />
                    </button>
                    <div className="flex flex-col pt-16 px-5 w-full h-full space-y-[2.2vh]">
                      <div className="text-[#9A2C6A] text-2xl md:text-3xl lg:text-4xl font-latoBold text-center">
                        {t("ofrendaengrupo")}
                      </div>
                      <div className="color-azul-oscuro text-lg md:text-xl lg:text-2xl font-[Montserrat]">
                        {t("eligegrupounion")}
                      </div>
                      <div className="w-full">
                        <img className="absolute right-8 h-7 mt-2" src={lupa} />
                        <input
                          id="myinput"
                          type="text"
                          list="groups"
                          placeholder={t("buscargrupo")}
                          onFocus={() => {
                            // setinputbuscadorfocus(true);
                          }}
                          onInput={(e) => {
                            e.currentTarget.value = e.currentTarget.value
                              .toUpperCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "");
                          }}
                          className="border-2 border-[#9A2C6A] text-[#9A2C6A] placeholder-[#9A2C6A] w-full p-2 font-[Montserrat] text-lg md:text-xl lg:text-2xl pr-12"
                          onChange={(e) => {
                            grupos.sort();
                            var misGrupos = grupos.filter((name: any) =>
                              name["nombre"]
                                .toUpperCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .includes(e.currentTarget.value)
                            );
                            setgrupoSelected("");
                            setgrupoSelectedCompleto("");
                            setfiltro(misGrupos);
                          }}
                        />
                      </div>
                      <div
                        style={{ height: "40vh" }}
                        className="flex flex-col bg-[#F2F0E9] overflow-auto divide-y-2 "
                      >
                        {filtro.map((item, index) => (
                          <li
                            className="text-[#9A2C6A] flex pl-2 py-5  font-[Montserrat] text-lg md:text-xl lg:text-2xl"
                            onClick={() => {
                              var e = document.getElementById("myinput");
                              //@ts-ignore
                              e.value = item["nombre"];
                              setgrupoSelected(
                                //@ts-ignore
                                item["nombre"].length >= 28
                                  ? //@ts-ignore
                                    item["nombre"].substring(0, 28) + "..."
                                  : item["nombre"]
                              );
                              setgrupoSelectedCompleto(item["nombre"]);
                            }}
                            key={index}
                          >
                            {item["nombre"]}
                          </li>
                        ))}
                      </div>
                    </div>

                    {(!inputbuscadorfocus || grupoSelected) && (
                      <div className="flex flex-col absolute bottom-10 w-full items-center text-center justify-center ">
                        {!grupoSelected && (
                          <div className="pb-4 text-[#233348] text-md">
                            {t("nohaselegidogrupo")}
                          </div>
                        )}

                        <button
                          onClick={() => {
                            sendMatomoEvent(
                              MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                              `${APP_SCREENS.SCREEN2}: ${MATOMO_ANALYTICS_ACTION.CAMBIAR_GRUPO}`
                            );
                            setbuscadorGrupo(false);
                          }}
                          disabled={grupoSelected !== "" ? false : true}
                          className={`flex text-center rounded-md h-12 justify-center items-center  font-latoBold ${
                            grupoSelected === ""
                              ? "bg-white border-2 border-gray-400 text-gray-400 text-center px-8"
                              : "bg-white border-2 border-[#9A2C6A] text-[#9A2C6A] text-center px-8"
                          }  text-lg md:text-xl lg:text-2xl`}
                        >
                          {t("anadirgrupo")}
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
          {escena === 1 && (
            <>
              <div className="w-full h-full font-[Montserrat] bg-[#F2F0E9] ">
                <div className="absolute flex flex-col w-full overflow-hidden bg-[#F2F0E9] z-50">
                  <div className="flex  w-full justify-between items-center z-50 py-5 px-4 sm:px-8">
                    <img src={logofiestas} className="w-24 sm:w-32"></img>
                    <div className="pl-4 font-[MontserratBold] text-xl md:text-xl lg:text-2xl">
                      {t("pilar23")}
                    </div>
                  </div>
                  {/* <div
                style={{ height: "7%" }}
                className="flex h-full flex-row justify-between items-center py-1 py-5 px-4 sm:px-8"
              >
                <img src={logofiestas} className="w-24 sm:w-32"></img>
                <div className="pl-4 font-[MontserratBold]">{t("pilar23")}</div>
              </div> */}
                  {alturaOfrendaRamo === -1 ? (
                    <div className="font-latoBold text-[#9A2C6A] py-2 px-4 text-base md:text-lg lg:text-xl z-50">
                      {t("donequieres")}
                    </div>
                  ) : (
                    <div className="font-latoBold text-[#9A2C6A] py-2 pl-3 text-base md:text-lg lg:text-xl z-50">
                      {t("colocando")}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(117,181,188,1) 0%, rgba(235,224,187,1) 59%, rgba(236,220,208,1) 100%)",
                  }}
                  className="absolute w-full h-full  justify-center  overflow-hidden "
                >
                  {/* NUBE */}
                  <motion.div
                    className="absolute w-28 z-10 overflow-hidden"
                    initial={{
                      x: "80vw",
                      y: "24vh",
                    }}
                    animate={{
                      x: -200,
                      y: "24vh",
                    }}
                    transition={{ duration: 18.5, repeat: Infinity }}
                  >
                    <img src={nube1}></img>
                  </motion.div>
                  <motion.div
                    className="absolute w-28 z-10 overflow-hidden"
                    initial={{
                      x: "20vw",
                      y: "15vh",
                    }}
                    animate={{
                      x: -200,
                      y: "15vh",
                    }}
                    transition={{ duration: 16, repeat: Infinity }}
                  >
                    <img src={nube2}></img>
                  </motion.div>
                  <img
                    className="absolute bottom-[30%] m-auto w-full"
                    src={lines}
                  ></img>
                  <div
                    className="absolute bottom-12 overflow-hidden z-20 px-10 "
                    style={{
                      background: `url(${ofrenda})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "100vw",
                      height: "80vh",
                    }}
                  >
                    {alturaOfrendaRamo !== -1 && !bienhechobool && (
                      <motion.div
                        className="w-24 overflow-hidden"
                        initial={{
                          y: -100,
                          x: getRandomIntInclusive(50, 230),
                        }}
                        animate={{
                          y:
                            alturaOfrendaRamo === 1
                              ? "27vh"
                              : alturaOfrendaRamo === 2
                              ? "42vh"
                              : "58vh",
                          x: getRandomIntInclusive(50, 230),
                        }}
                        transition={{ duration: 2.5 }}
                      >
                        <img src={ramos[ramoSelected - 1]}></img>
                      </motion.div>
                    )}
                    {alturaOfrendaRamo === -1 && (
                      <div
                        style={{ paddingBottom: "13vh" }}
                        className="flex flex-col justify-end w-full h-full items-center"
                      >
                        <button
                          style={{
                            marginBottom: "4vh",
                            width: "10vh",
                            height: "10vh",
                          }}
                          className="flex  justify-center items-center  "
                          onClick={() => {
                            setalturaOfrendaRamo(1);
                            setTimeout(() => {
                              setbienhecho(true);
                            }, 4000);
                            setTimeout(() => {
                              setescena(2);
                            }, 7000);
                          }}
                        >
                          <img className="" src={imgRamo}></img>
                        </button>
                        <button
                          style={{
                            marginBottom: "5vh",
                            width: "10vh",
                            height: "10vh",
                          }}
                          className="flex  justify-center items-center  "
                          onClick={() => {
                            setalturaOfrendaRamo(2);
                            setTimeout(() => {
                              setbienhecho(true);
                            }, 4000);
                            setTimeout(() => {
                              setescena(2);
                            }, 7000);
                          }}
                        >
                          <img className="" src={imgRamo}></img>
                        </button>
                        <button
                          style={{
                            marginBottom: "-6vh",
                            width: "10vh",
                            height: "10vh",
                          }}
                          className="flex  justify-center items-center "
                          onClick={() => {
                            setalturaOfrendaRamo(3);
                            setTimeout(() => {
                              setbienhecho(true);
                            }, 4000);
                            setTimeout(() => {
                              setescena(2);
                            }, 7000);
                          }}
                        >
                          <img className="" src={imgRamo}></img>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {alturaOfrendaRamo === -1 && (
                  <div
                    style={{
                      height: "10%",
                      backgroundColor: "rgb(228, 228, 228)",
                    }}
                    className={`absolute fondo-blanco-gris flex justify-between bottom-0 w-full px-3 items-center z-40`}
                  >
                    <>
                      <button
                        onClick={() => {
                          setescena(0);
                        }}
                        className="flex scale-95 px-10 items-center justify-center p-4 text-[#9A2C6A] underline  text-xl md:text-xl lg:text-2xl "
                      >
                        <svg
                          className="rotate-180 mr-2"
                          width="25"
                          height="16"
                          viewBox="0 0 25 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 7.125C0.447715 7.125 5.68981e-08 7.57272 0 8.125C-5.68981e-08 8.67728 0.447715 9.125 1 9.125L1 7.125ZM24.3922 8.83211C24.7828 8.44158 24.7828 7.80842 24.3922 7.41789L18.0283 1.05393C17.6377 0.663409 17.0046 0.663409 16.6141 1.05393C16.2235 1.44446 16.2235 2.07762 16.6141 2.46815L22.2709 8.125L16.6141 13.7819C16.2235 14.1724 16.2235 14.8055 16.6141 15.1961C17.0046 15.5866 17.6377 15.5866 18.0283 15.1961L24.3922 8.83211ZM1 9.125L23.6851 9.125L23.6851 7.125L1 7.125L1 9.125Z"
                            fill="#9A2C6A"
                          />
                        </svg>
                        {t("atras")}
                      </button>
                      <button
                        onClick={() => {
                          aleatorioClicked.current = true;
                          sendMatomoEvent(
                            MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                            `${APP_SCREENS.SCREEN3}: ${MATOMO_ANALYTICS_ACTION.NIVEL_ALEATORIO}`
                          );
                          setalturaOfrendaRamo(getRandomIntInclusive(0, 3));
                          setTimeout(() => {
                            setbienhecho(true);
                          }, 4000);
                          setTimeout(() => {
                            setescena(2);
                          }, 7000);
                        }}
                        className="flex px-6 font-latoBold justify-center items-center rounded-full py-4 text-white text-lg md:text-xl lg:text-2xl bg-[#9A2C6A] "
                      >
                        {t("siguiente")}
                        <img className="flex pl-4 " src={flecha}></img>
                      </button>
                    </>
                  </div>
                )}
                {/* <button
              onClick={() => {
                // var c = document.getElementById("myCanvas");
                // //@ts-ignore
                // setimagenCtx(c.toDataURL());
                // setescena(1);
                // setTimeout(() => {
                //   setescena(2);
                // }, 100);
              }}
              className="flex px-10 rounded-md items-center justify-center p-4 mb-4 bgtext-[#9A2C6A] border-2 text-white text-lg md:text-xl lg:text-2xl bg-gradient-to-t from-cyan-500 to-blue-500"
            >
              {t("iralpilar")}
              <img className="flex pl-4 " src={flecha}></img>
            </button> */}
                {bienhechobool && (
                  <motion.div
                    initial={{ background: "rgba(0,0,0,0)" }}
                    animate={{ background: "rgba(0,0,0,0.8)" }}
                    transition={{ duration: 0.4 }}
                    className="relative flex w-full h-full justify-center items-center z-50"
                  >
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.6 }}
                      className="flex flex-col justify-center pt-4 items-center rounded-xl w-full h-full s-auto z-40 px-20"
                    >
                      <img
                        src={bienhecho}
                        className="flex items-center w-[16rem] z-40"
                      />
                      <div className="absolute w-[20rem] justify-between items-center  bg-white m-auto flex h-20 z-50 text-center text-2xl font-latoBold color-azul-claro">
                        <div className="w-2 h-full bg-[#E85896]" />
                        <div>{t("ofrendahecha")}</div>
                        <div className="w-2 h-full bg-[#E85896]" />
                      </div>
                    </motion.div>
                  </motion.div>
                )}
              </div>
            </>
          )}
          {escena === 2 && (
            <>
              {openModalTwitter && (
                <motion.div
                  initial={{ background: "rgba(0,0,0,0)" }}
                  animate={{ background: "rgba(0,0,0,0.8)" }}
                  transition={{ duration: 0.4 }}
                  className="absolute flex w-full h-full justify-center items-center z-50 font-[Montserrat]"
                >
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.6 }}
                    className="relative flex flex-col space-y-4 pt-14 pb-4 px-4 justify-center font-latoBold items-center color-azul-claro rounded-xl w-80 z-40 bg-white"
                  >
                    <button
                      onClick={() => {
                        setopenModalTwitter(false);
                      }}
                      className="absolute h-8 w-8 right-2 top-2 z-50"
                    >
                      <img src={close} />
                    </button>
                    <div className="flex z-50  text-xl font-latoBold color-azul-claro">
                      {t("quieresparticiparZaragoza")}
                    </div>
                    <div className="flex pt-7 z-50  text-base font-[Montserrat] color-azul-claro">
                      {t("twittea")}
                    </div>
                    <button
                      onClick={() => {
                        sendMatomoEvent(
                          MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                          `${APP_SCREENS.SCREEN5}: ${MATOMO_ANALYTICS_ACTION.CODIGO_CONCURSO_COPIADO}`
                        );
                        navigator.clipboard.writeText(codeTwitter);
                      }}
                      className="flex relative w-full pl-4 pr-10 text-sm md:text-md lg:text-lg items-center h-10 rounded-md bg-[#5C80D6] bg-opacity-10 border-[1px] border-azul-claro"
                    >
                      {codeTwitter}
                      <svg
                        className="absolute right-2"
                        width="21"
                        height="25"
                        viewBox="0 0 21 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.9306 0.714355H2.13295C0.959827 0.714355 0 1.67864 0 2.85721V17.8572H2.13295V2.85721H14.9306V0.714355ZM18.1301 5.00007H6.39884C5.22572 5.00007 4.2659 5.96436 4.2659 7.14293V22.1429C4.2659 23.3215 5.22572 24.2858 6.39884 24.2858H18.1301C19.3032 24.2858 20.263 23.3215 20.263 22.1429V7.14293C20.263 5.96436 19.3032 5.00007 18.1301 5.00007ZM18.1301 22.1429H6.39884V7.14293H18.1301V22.1429Z"
                          fill="black"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        sendMatomoEvent(
                          MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                          `${APP_SCREENS.SCREEN5}: ${MATOMO_ANALYTICS_ACTION.CODIGO_CONCURSO_COMPARTIDO}`
                        );
                        window.open(
                          idioma === "es"
                            ? "https://twitter.com/intent/tweet?text=Acabo%20de%20hacer%20mi%20ofrenda%20virtual%20y%20ya%20puedo%20participar%20en%20el%20sorteo%20internacional%20%23VenAlaOfrenda%20%5B" +
                                codeTwitter +
                                "%5D%20%C2%A1para%20viajar%20a%20Zaragoza%20en%202023%20y%20vivir%20las%20%23FiestasdelPilar%20en%20persona%21"
                            : "https://twitter.com/intent/tweet?text=I%20just%20made%20my%20virtual%20offering%20and%20I%20can%20now%20participate%20in%20the%20international%20raffle%20%23VenAlaOfrenda%20%5B" +
                                codeTwitter +
                                "%5D%20to%20travel%20to%20Zaragoza%20in%202023%20and%20experience%20the%20%23FiestasdelPilar%20in%20person%21"
                        );
                      }}
                      className="flex flex-row pl-6  w-48 rounded-md justify-center p-3 pt-4 h-12 items-center text-white bg-gradient-to-b from-[#299DED] to-[#3387be] text-lg md:text-xl lg:text-2xl font-latoBold"
                    >
                      <div>{t("compartir")}</div>
                      <svg
                        className="ml-3"
                        width="37"
                        height="39"
                        viewBox="0 0 48 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.704526 34.5626C5.19519 37.3725 10.4215 38.8571 15.8176 38.8571C23.7197 38.8571 30.9609 35.8639 36.2095 30.4294C41.2317 25.2281 43.9944 18.2393 43.8569 11.1468C45.7607 9.54055 48 6.47358 48 3.48433C48 2.33845 46.7389 1.61106 45.7163 2.19496C43.9277 3.23124 42.2967 3.50226 40.6193 3.03793C37.1937 -0.254232 32.0947 -0.947739 27.7981 1.33605C24.043 3.32888 21.9554 6.97577 22.1433 10.9614C15.7994 10.1982 9.93848 7.06146 5.85202 2.18699C5.18104 1.39185 3.90781 1.48551 3.37427 2.38627C1.40581 5.71032 1.42602 9.56247 3.13781 12.6733C2.32335 12.8148 1.82012 13.4903 1.82012 14.2217C1.82012 17.3485 3.24695 20.2221 5.54482 22.1831C5.11637 22.5896 4.9749 23.1935 5.15679 23.7315C6.16729 26.7248 8.45507 29.0644 11.3148 30.2361C8.20446 31.7009 4.76472 32.1891 1.7029 31.8185C0.118436 31.6072 -0.663691 33.7077 0.704526 34.5626ZM16.2319 30.7483C17.3657 29.8894 16.7634 28.0998 15.3406 28.0699C12.8346 28.0181 10.5529 26.8005 9.1321 24.8854C9.81722 24.8415 10.5266 24.7379 11.2016 24.5586C12.7396 24.148 12.6668 21.9599 11.1046 21.651C8.26913 21.089 6.04401 19.0524 5.20934 16.4298C5.97126 16.6151 6.74732 16.7187 7.52136 16.7327C9.0553 16.7407 9.63533 14.8016 8.38433 13.9906C5.56503 12.1591 4.36254 8.92476 5.15881 5.8837C10.082 10.802 16.7614 13.7654 23.8268 14.1002C24.8393 14.1619 25.5992 13.2233 25.3769 12.2727C24.4169 8.16948 26.7411 5.29382 29.237 3.96859C31.7067 2.65331 35.6719 2.24279 38.7115 5.38748C39.6149 6.32611 42.6625 6.36198 44.2126 6.00526C43.5174 7.29662 42.4483 8.52221 41.4479 9.21173C41.0215 9.50667 40.7769 9.99691 40.8032 10.5091C41.1286 17.0555 38.6549 23.5641 34.0167 28.3649C29.3441 33.2015 22.883 35.8659 15.8196 35.8659C13.0104 35.8659 10.2558 35.4155 7.65273 34.5466C10.7651 33.9528 13.7218 32.6535 16.2319 30.7483Z"
                          fill="#ffffff"
                        />
                      </svg>
                    </button>
                    <div className="flex w-full h-20 items-center justify-center pt-10">
                      <a
                        target={"_blank"}
                        className="flex px-4 h-full items-center text-sm md:text-md lg:text-lg rounded-md border-[1px] border-azul-claro"
                        href={APP_ROUTES.BASES}
                      >
                        {t("basesconcurso")}
                      </a>
                    </div>
                    {/* <img src={bienhecho} className="flex items-center h-48 z-40" />
                <div className="flex h-20 z-50 text-center text-2xl font-latoBold color-azul-claro">
                  {t("ofrendahecha")}
                </div> */}
                  </motion.div>
                </motion.div>
              )}
              <div
                style={{
                  backgroundColor: "#F2F0E9",
                }}
                className="w-full h-full flex flex-col"
              >
                {/* <div className="flex w-full py-5 justify-between items-center border-b-2 bg-white">
                  <div className="pl-4 font-[MontserratBold]">{t("pilar22")}</div>
                  <img src={logo} className="h-5 pr-4"></img>
                </div> */}
                <div className="absolute flex flex-col h-full w-full ">
                  <div className="absolute flex  w-full  justify-between items-center z-50 py-5 px-4 sm:px-8">
                    <img src={logofiestas} className="w-24 sm:w-32"></img>
                    <div className="pl-4 font-[MontserratBold] text-xl md:text-xl lg:text-2xl">
                      {t("pilar23")}
                    </div>
                  </div>
                  <div
                    className="flex bg-[#F2F0E9] flex-col w-full h-full items-center justify-start"
                    style={{
                      background: `url(${bgofering})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "100vw",
                      height: "100vh",
                    }}
                  >
                    <div className="flex flex-col items-center w-full h-full justify-center ">
                      <div className="relative flex flex-row w-full px-[28%] gap-[20%] pb-8 items-center justify-center pt-10 mt-10">
                        <img className="scale-[100%] h-[30%]" src={flores1} />
                        <img className="scale-[110%]" src={imagenCtx} />
                        <img
                          className="-scale-x-[100%] h-[30%]"
                          src={flores1}
                        />
                        {/* <img className="flex absolute w-16 -bottom-6" src={check}></img> */}
                      </div>
                      <div className="flex flex-col w-full justify-between text-center pb-3 items-center px-4 space-y-[5%] lato-fontRe">
                        <div className="flex  font-[MontserratBold] color-azul-claro text-xl md:text-2xl lg:text-3xl pt-3">
                          {t("ofendaHecha")}
                          <br />
                          {t("ofendaHecha2")}
                          {errorPeticion === true
                            ? ""
                            : idioma === "es"
                            ? " Nº" + ofrendasrealizadas
                            : " #" + ofrendasrealizadas}
                          !
                          {/* {totalGroupOfferings !== 0 && (
                            <div className="flex font-[MontserratBold] color-azul-claro text-xl md:text-2xl lg:text-3xl ">
                              {t("tugrupo")}{" "}
                              {!errorPeticion && totalGroupOfferings}{" "}
                              {t("ofrendas")}
                            </div>
                          )} */}
                        </div>
                        <div className="flex items-center justify-center gap-4 scale-90">
                          <a
                            onClick={() =>
                              sendMatomoEvent(
                                MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                                `${APP_SCREENS.SCREEN4}: ${MATOMO_ANALYTICS_ACTION.OFRENDA_GUARDADA}`
                              )
                            }
                            download="ofrendadeflores"
                            href={imagenCtx}
                            className="flex flex-row  rounded-full p-6 justify-center text-[#9A2C6A]  h-12 items-center text bg-white border-2 border-[#9A2C6A] text-lg md:text-xl lg:text-2xl font-latoBold"
                          >
                            <div className="pt-1"> {t("guardar")}</div>
                            <img className="flex pl-4 w-10" src={guardar}></img>
                          </a>
                          <a
                            onClick={() =>
                              sendMatomoEvent(
                                MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                                `${APP_SCREENS.SCREEN4}: ${MATOMO_ANALYTICS_ACTION.OFRENDA_COMPARTIDA}`
                              )
                            }
                            target="_blank"
                            href={
                              idioma === "es"
                                ? `https://api.whatsapp.com/send?text=💐¡Felices fiestas 2023!💐 Acabo de realizar mi Ofrenda a la Virgen del Pilar🎉❤️ https%3A%2F%2Fshare.ofrendadeflores.com%2Fshare%3Fimg%3D${idImg} 🌺¿Quieres hacer la tuya? Sigue los pasos de este link https%3A%2F%2Fofrendadeflores.com/express`
                                : `https://api.whatsapp.com/send?text=💐Happy 2023 festivities!💐 I have just made my Ofrenda to the Virgen del Pilar🎉❤️ https%3A%2F%2Fshare.ofrendadeflores.com%2Fshare%3Fimg%3D${idImg} 🌺Do you want to make yours? Click on the following link https%3A%2F%2Fofrendadeflores.com/express`
                            }
                            data-action="share/whatsapp/share"
                            className="flex flex-row  rounded-full p-6 justify-center text-white h-12 items-center text bg-[#19942D] border-2 border-[#19942D] text-lg md:text-xl lg:text-2xl font-latoBold"
                          >
                            <div className="pt-1">{t("compartir")}</div>
                            <img
                              className="flex w-12 pl-4 "
                              src={whatsapp}
                            ></img>
                          </a>
                        </div>
                        {!locationSpain && (
                          <div className="flex flex-col justify-center items-center w-full text-center">
                            <div className="font-[Montserrat] pb-1 casi-negro">
                              {t("quieresparticipar")}
                            </div>
                            <button
                              onClick={() => {
                                sendMatomoEvent(
                                  MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                                  `${APP_SCREENS.SCREEN4}: ${MATOMO_ANALYTICS_ACTION.ACCESO_CONCURSO_TURISMO}`
                                );
                                setopenModalTwitter(true);
                              }}
                              className="flex flex-row pl-6 w-24 rounded-md justify-center p-3 h-12 items-center text-white bg-gradient-to-b from-[#299DED] to-[#3387be] text-lg md:text-xl lg:text-2xl font-latoBold"
                            >
                              <div>{t("participar")}</div>
                              <svg
                                className="ml-3"
                                width="37"
                                height="39"
                                viewBox="0 0 48 39"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.704526 34.5626C5.19519 37.3725 10.4215 38.8571 15.8176 38.8571C23.7197 38.8571 30.9609 35.8639 36.2095 30.4294C41.2317 25.2281 43.9944 18.2393 43.8569 11.1468C45.7607 9.54055 48 6.47358 48 3.48433C48 2.33845 46.7389 1.61106 45.7163 2.19496C43.9277 3.23124 42.2967 3.50226 40.6193 3.03793C37.1937 -0.254232 32.0947 -0.947739 27.7981 1.33605C24.043 3.32888 21.9554 6.97577 22.1433 10.9614C15.7994 10.1982 9.93848 7.06146 5.85202 2.18699C5.18104 1.39185 3.90781 1.48551 3.37427 2.38627C1.40581 5.71032 1.42602 9.56247 3.13781 12.6733C2.32335 12.8148 1.82012 13.4903 1.82012 14.2217C1.82012 17.3485 3.24695 20.2221 5.54482 22.1831C5.11637 22.5896 4.9749 23.1935 5.15679 23.7315C6.16729 26.7248 8.45507 29.0644 11.3148 30.2361C8.20446 31.7009 4.76472 32.1891 1.7029 31.8185C0.118436 31.6072 -0.663691 33.7077 0.704526 34.5626ZM16.2319 30.7483C17.3657 29.8894 16.7634 28.0998 15.3406 28.0699C12.8346 28.0181 10.5529 26.8005 9.1321 24.8854C9.81722 24.8415 10.5266 24.7379 11.2016 24.5586C12.7396 24.148 12.6668 21.9599 11.1046 21.651C8.26913 21.089 6.04401 19.0524 5.20934 16.4298C5.97126 16.6151 6.74732 16.7187 7.52136 16.7327C9.0553 16.7407 9.63533 14.8016 8.38433 13.9906C5.56503 12.1591 4.36254 8.92476 5.15881 5.8837C10.082 10.802 16.7614 13.7654 23.8268 14.1002C24.8393 14.1619 25.5992 13.2233 25.3769 12.2727C24.4169 8.16948 26.7411 5.29382 29.237 3.96859C31.7067 2.65331 35.6719 2.24279 38.7115 5.38748C39.6149 6.32611 42.6625 6.36198 44.2126 6.00526C43.5174 7.29662 42.4483 8.52221 41.4479 9.21173C41.0215 9.50667 40.7769 9.99691 40.8032 10.5091C41.1286 17.0555 38.6549 23.5641 34.0167 28.3649C29.3441 33.2015 22.883 35.8659 15.8196 35.8659C13.0104 35.8659 10.2558 35.4155 7.65273 34.5466C10.7651 33.9528 13.7218 32.6535 16.2319 30.7483Z"
                                  fill="#ffffff"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col h-[50%] w-full justify-end">
                      {grupoSelectedCompleto && (
                        <>
                          <div className=" flex flex-col bg-[#EBE0BB] w-full h-full justify-center items-center gap-4 py-4 px-4 max-h-[20vh]">
                            <img className="h-12" src={notepierdasnada}></img>
                            <div className="flex gap-1 color-azul-claro text-[2vh] font-[Montserrat]">
                              <div>{t("ofering1")}</div>
                              <div className="font-[MontserratBold]">
                                {"#" + groupIndex + " "}
                              </div>
                              <div>{t("ofering2")}</div>
                              <div className="font-[MontserratBold]">
                                {totalGroupOfferings + " "}
                              </div>
                              <div>{t("ofering3")}</div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className=" flex flex-col bg-[#d6b0c6] w-full justify-center h-full items-center gap-4 py-4 max-h-[20vh]">
                        {/* <div className="color-azul-claro text-[4vw] font-[MontserratBold]">
                          {t("notepierdas")}
                        </div> */}
                        <div className="flex gap-4 justify-center items-center w-full">
                          <img src={flores2} className="h-6 -scale-x-100"></img>
                          <div className="flex flex-row rounded-full  p-6 justify-center text-[#9A2C6A] h-12 items-center text bg-white border-2 border-[#9A2C6A] text-lg md:text-xl lg:text-2xl font-latoBold">
                            <div
                              onClick={() => {
                                sendMatomoEvent(
                                  MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                                  `${APP_SCREENS.SCREEN4}: ${MATOMO_ANALYTICS_ACTION.ACCESO_3D_AFTER}`
                                );
                                window.sessionStorage.setItem(
                                  "enableVisit",
                                  "1"
                                );
                                window.open("/virtual", "_self");
                                // var c = document.getElementById("myCanvas");
                                // //@ts-ignore
                                // setimagenCtx(c.toDataURL());
                                // setescena(1);
                                // setTimeout(() => {
                                //   setescena(2);
                                // }, 100);
                              }}
                              className=""
                            >
                              {t("iralpilar")}
                            </div>
                            {/* <svg
                              className="ml-4 mb-1"
                              width="32"
                              height="20"
                              viewBox="0 0 25 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 7.125C0.447715 7.125 5.68981e-08 7.57272 0 8.125C-5.68981e-08 8.67728 0.447715 9.125 1 9.125L1 7.125ZM24.3922 8.83211C24.7828 8.44158 24.7828 7.80842 24.3922 7.41789L18.0283 1.05393C17.6377 0.663409 17.0046 0.663409 16.6141 1.05393C16.2235 1.44446 16.2235 2.07762 16.6141 2.46815L22.2709 8.125L16.6141 13.7819C16.2235 14.1724 16.2235 14.8055 16.6141 15.1961C17.0046 15.5866 17.6377 15.5866 18.0283 15.1961L24.3922 8.83211ZM1 9.125L23.6851 9.125L23.6851 7.125L1 7.125L1 9.125Z"
                                fill="#9A2C6A"
                              />
                            </svg> */}
                          </div>
                          <img src={flores2} className="h-6 "></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div
                  style={{
                    backgroundColor: "#F0F5FF",
                  }}
                  className="flex flex-col w-full px-10 border-t-2 font-[Montserrat] pt-2 pb-2"
                >
                  <div className="flex font-[Montserrat] w-full text-center color-azul-oscuro text-md md:text-lg lg:text-xl pb-2">
                    {t("notepierdas")}
                  </div>
                  <button
                    onClick={() => {
                      sendMatomoEvent(
                        MATOMO_ANALYTICS_CATEGORY.LANDING2D,
                        `${APP_SCREENS.SCREEN4}: ${MATOMO_ANALYTICS_ACTION.ACCESO_3D_AFTER}`
                      );
                      window.sessionStorage.setItem("enableVisit", "1");
                      window.open("/virtual", "_self");
                      // var c = document.getElementById("myCanvas");
                      // //@ts-ignore
                      // setimagenCtx(c.toDataURL());
                      // setescena(1);
                      // setTimeout(() => {
                      //   setescena(2);
                      // }, 100);
                    }}
                    className="flex px-10 rounded-md items-center justify-center p-3 pb-2 bgcolor-azul-claro border-2 text-white text-lg md:text-xl lg:text-2xl bg-gradient-to-t from-cyan-500 to-blue-500"
                  >
                    {t("iralpilar")}
                    <img className="flex pl-4 " src={flecha}></img>
                  </button>
                </div> */}
              </div>
            </>
          )}
        </>
      ) : (
        <Orientation />
      )}
    </>
  );
};

export default OfrendaExpress;
