import create, {State} from "zustand";
import Cookies from "js-cookie";
import {NAME_COOKIES} from "../utils/utils";

interface CookiesStoreState {
  cookies: boolean;
  analyticsCookie: boolean;
  //preferencesCookie: boolean;
  setCookies: (ck: boolean, an: boolean) => void;
}

const CookiesStore = create<CookiesStoreState>((set) => ({
  cookies: Cookies.get(NAME_COOKIES.CONSENT) ? true : false,
  analyticsCookie: Cookies.get(NAME_COOKIES.ANALYTICS) ? true : false,
  //preferencesCookie:
  //Cookies.get(NAME_COOKIES.PREFERENCES) === "true" ? true : false,
  setCookies: (ck, an) => set({cookies: ck, analyticsCookie: an}),
}));

export default CookiesStore;
