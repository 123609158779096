import { useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import "./styles.css";
import rotateDevice from "../src/assets/images/orientation/rotateDevice.png";
const Orientation = () => {
  const { t } = useTranslation();
  const [minified, setMinified] = useState<boolean>(false);
  const [size, setSize] = useState<number>(window.innerHeight / 17.5);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setSize(window.innerHeight / 17.5);
    });
  }, []);

  return (
    <div
    id="landscapeWarning"
    className="absolute w-full h-full z-[100] overflow-hidden bg-[#F2F0E9]">
    <div className="w-full h-full flex flex-col justify-center items-center text-[#233348]">
      <img
        className="w-32 lg:w-48 mb-4 lg:mb-14"
        src={rotateDevice} />
      <div className="text-base lg:text-2xl font-[MontserratBold] text-center lg:mb-4">
        Pon tu móvil en vertical para seguir disfrutando de la experiencia
      </div>
      <div className="text-base lg:text-2xl text-center">
        Turn your cell phone upright to continue enjoying the experience
      </div>
    </div>
  </div>
  );
};

export default Orientation;
