import { useTranslation } from "react-i18next";
import { Link } from "wouter";
import logo from "../src/assets/images/landing/Logoayuntamiento.png";
import { APP_ROUTES } from "./utils/utils";
const HeaderCookiesPrivacy = () => {
  const { t } = useTranslation();
  return (
    <div className="flex p-4 bg-cookiesBlue justify-center relative">
      {/* <Link to={APP_ROUTES.HOME} className=""> */}
      <div
        onClick={() => window.history.back()}
        className="absolute left-0 flex items-center font-bold text-white font-gatterSansRegular px-2 top-1/2 -translate-y-1/2"
      >
        {" "}
        <svg
          className="rotate-180 mr-2"
          width="25"
          height="16"
          viewBox="0 0 25 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 7.125C0.447715 7.125 5.68981e-08 7.57272 0 8.125C-5.68981e-08 8.67728 0.447715 9.125 1 9.125L1 7.125ZM24.3922 8.83211C24.7828 8.44158 24.7828 7.80842 24.3922 7.41789L18.0283 1.05393C17.6377 0.663409 17.0046 0.663409 16.6141 1.05393C16.2235 1.44446 16.2235 2.07762 16.6141 2.46815L22.2709 8.125L16.6141 13.7819C16.2235 14.1724 16.2235 14.8055 16.6141 15.1961C17.0046 15.5866 17.6377 15.5866 18.0283 15.1961L24.3922 8.83211ZM1 9.125L23.6851 9.125L23.6851 7.125L1 7.125L1 9.125Z"
            fill="#ffffff"
          />
        </svg>{" "}
        <span className="uppercase">{t("atras")}</span>
      </div>
      {/* </Link> */}
      <img src={logo} width="50" />
    </div>
  );
};

export default HeaderCookiesPrivacy;
