import { useEffect } from "react";
import { Link } from "wouter";
import logo from "../src/assets/images/landing/Logoayuntamiento.png";
import HeaderCookiesPrivacy from "./HeaderCookiesPrivacy";
import "./styles.css";
const BasesSpanish = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <HeaderCookiesPrivacy />
      <div className="px-4 py-8">
        <h1 className=" uppercase text-xl font-latoBold">
          BASES CONCURSO ‘#VenAlaOfrenda
        </h1>
        <h2 className="mt-6 mb-2 font-latoBold">Compañía organizadora</h2>
        <div className="font-latoNormal text-sm">
          <p>
            La Sociedad Municipal Zaragoza Cultural SAU (en adelante ZC) con
            domicilio social, a efectos de notificaciones, citaciones y
            emplazamientos en Zaragoza, en Torreón Fortea, calle de Torrenueva
            25 con NIF A-50377787, y Zaragoza Turismo (en adelante ZT),
            organizan el CONCURSO denominado<b> #VenAlaOfrenda</b>.
          </p>

          <h2 className="mt-6 mb-2 font-latoBold">
            Desvinculación con respecto a Twitter
          </h2>
          <p>
            Facebook no patrocina, avala ni administra de modo alguno esta
            promoción, ni está asociado a ella. El usuario se desvincula
            totalmente de Twitter y es consciente de que está proporcionando su
            información ZC y ZT, y no a Twitter. La información que proporcione
            se utilizará para gestionar la participación del concursante y para
            comunicarle el premio.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Objeto y mecánica del concurso
          </h2>
          <p>
            Podrán participar en el concurso, los usuarios que{" "}
            <b>realicen la ofrenda de flores virtual</b> en la web:
            <a href="www.ofrendadeflores.com" className="underline">
              {" "}
              www.ofrendadeflores.com{" "}
            </a>
            desde una ubicación que esté{" "}
            <b>fuera de territorio nacional de España</b>. Para participar en el
            sorteo es necesario publicar un tweet con el
            <b> código único </b> obtenido tras hacer la ofrenda, incluyendo en
            el tweet el hashtag <b>#VenAlaOfrenda.</b>
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Requisitos para participar
          </h2>
          <p>
            Podrán participar en el concurso <b>#VenAlaOfrenda</b> todas las
            personas físicas mayores de 18 años que no residan en España. No
            podrán participar las personas relacionadas con la organización de
            este concurso. Quedarán excluidas de la participación en el concurso
            quienes no cumplan con los requisitos, ni con la mecánica ni plazo
            de presentación al mismo.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Selección del ganador, suplentes y comunicación
          </h2>
          <p>
            Entre los/las participantes se extrae una lista de cuentas que han
            tuiteado códigos válidos generados en www.ofrendadeflores.com. Sólo
            será válida una participación con código válido por cada cuenta de
            Twitter. Se descartarán todos aquellos tweets que contengan un
            código válido previamente incluido en otro tweet. Se realizará un
            sorteo mediante aplicación informática entre todos los usuarios que
            participen. Habrá un solo ganador. El nombre del ganador se
            publicará{" "}
            <b>en la cuenta oficial de twitter de Fiestas del Pilar</b> el 18 de
            octubre. El ganador cede los derechos para ser fotografiado en el
            momento de la entrega del premio o durante su visita, y para que
            dichas fotografías sean utilizadas por Zaragoza Cultura y Zaragoza
            Turismo.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Premios</h2>
          <p>
            El premio del concurso consiste en un viaje para dos personas para
            visitar Zaragoza, durante las Ofrenda de Flores 2023. Este viaje
            incluirá transporte y alojamiento para estas dos personas por un
            importe no superior a 3.000 €.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Reservas y limitaciones</h2>
          <p>
            ZC y ZT queda eximido de cualquier responsabilidad en el supuesto de
            existir algún error en los datos facilitados por los propios
            agraciados que impidiera su identificación. ZC y ZT se reserva el
            derecho a aplazar, cancelar o ampliar el período del concurso, así
            como la facultad de interpretar las presentes bases legales.
            Asimismo, ZC y ZT quedarán exenta de toda responsabilidad si
            concurriere alguno de los casos señalados, así como de cualquier
            responsabilidad por los daños y perjuicios que pudiesen ocasionarse
            durante el disfrute del premio.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Protección de datos</h2>
          <p>
            Los datos suministrados por los participantes serán tratados
            confidencialmente. Zaragoza Cultural garantiza el cumplimiento de lo
            previsto en el REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL
            CONSEJO de 27 de abril de 2016 relativo a la protección de las
            personas físicas en lo que respecta al tratamiento de datos
            personales y a la libre circulación de estos datos (en lo sucesivo
            RGPD), le informamos a los usuarios sobre nuestra Política de
            Protección de Datos. en especial por lo que se refiere a la atención
            del ejercicio de los derechos de información, acceso, rectificación,
            oposición y cancelación de datos personales de los concursantes.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Responsable del tratamiento:
          </h2>
          <p>
            Sociedad Municipal Zaragoza Cultural, S.A.U <br />
            CIF: A50377787 <br />
            Dirección: C/ Torrenueva 25, 2ª planta - 50003 Zaragoza <br />
            E-mail: fiestasdelpilar@zaragozacultural.com <br />
            Contacto DPO: dpo@zaragozacultural.com <br />
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Finalidad del tratamiento</h2>
          <p>
            participación en el concurso. El nombre del ganador se hará público
            en la cuenta oficial de twitter, de Fiestas del Pilar.
            <br />
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Base jurídica del tratamiento
          </h2>
          <p>
            Consentimiento prestado al participar en el concurso. Artículo
            6.1.a) del RGPD.
            <br /> <br /> Plazo de conservación de los datos: Se conservarán
            durante los años necesarios para cumplir con las obligaciones
            legales derivadas del proceso en el que está participando. <br />
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            Plazo de conservación de los datos
          </h2>
          <p>
            Se conservarán durante los años necesarios para cumplir con las
            obligaciones legales derivadas del proceso en el que está
            participando.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Destinatarios</h2>
          <p>
            Los datos personales no se cederán a ningún tercero, salvo que la
            comunicación tenga como finalidad el cumplimiento de los fines
            directamente relacionados con las funciones legítimas del cedente y
            del cesionario, o el Titular haya otorgado su consentimiento a
            dichos efectos o cuando la cesión esté autorizada por alguna Ley. El
            resultado del concurso será publicado conforme a lo dispuesto en las
            presentes bases.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">Derechos</h2>
          <p>
            Le asisten los derechos de acceso, rectificación, oposición,
            portabilidad, supresión y limitación del tratamiento, sobre sus
            datos personales, pudiendo ejercerlos mediante escrito dirigido a la
            Unidad de Comunicación y Patrocinio de la Sociedad Municipal
            Zaragoza Cultural (sita en Torreón Fortea, C/ Torrenueva, 25 de
            Zaragoza). Asimismo, tiene la posibilidad de presentar una
            reclamación ante la Agencia Española de Protección de Datos en el
            caso de que considere que el tratamiento de sus datos no está siendo
            adecuado. <br /> <br /> Más información sobre protección de datos
            en: https://www.zaragoza.es/sede/portal/politica-privacidad
          </p>

          <h2 className="mt-6 mb-2 font-latoBold">Aceptación de las bases</h2>
          <p>
            La simple participación en el concurso implica la aceptación de las
            presentes Bases, por lo que la manifestación en el sentido de no
            aceptación de la totalidad o parte de las mismas implicará la
            exclusión del participante y como consecuencia de ello, Zaragoza
            Cultural quedará liberada del cumplimiento de la obligación
            contraída con dicho participante.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BasesSpanish;
