import { useEffect, useState } from "react";

const calculateCountdownTime = (timeLeft: number) => {
  return {
    days: Math.floor(timeLeft / (1000 * 60 * 60 * 24))
      .toString()
      .padStart(2, "0"),
    hours: Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      .toString()
      .padStart(2, "0"),
    minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, "0"),
    seconds: Math.floor((timeLeft % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, "0"),
  };
};

const useTimer = (date: Date) => {
  const countDownDate = date.getTime();
  const distance = countDownDate - Date.now();

  const countDown = calculateCountdownTime(distance);

  const [time, setTime] = useState(countDown);
  const [ended, setEnded] = useState(distance <= 0);

  useEffect(() => {
    const interval = setInterval(() => {
      const distance = countDownDate - Date.now();
      const countDown = calculateCountdownTime(distance);

      setTime(countDown);

      if (
        distance < 0 ||
        window.location.hostname === "d38fqk42m4mmos.cloudfront.net" ||
        window.location.hostname === "localhost"
      ) {
        setEnded(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return { time, ended };
};

export default useTimer;
