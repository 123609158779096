import "./App.css";
import { Link, Route, useLocation } from "wouter";
import Cookies from "js-cookie";
import i18n from "./i18n";

import CookiesStore from "./store/cookiesStore";
import { APP_ROUTES, NAME_COOKIES } from "./utils/utils";

import LandingVirtualSpace from "./LandingVirtualSpace2023";
import CookiesBanner from "./CookiesBanner";
import PrivacyPageEnglish from "./PrivacyPageEnglish";
import CookiesPageSpanish from "./CookiesPageSpanish";
import PrivacyPageSpanish from "./PrivacyPageSpanish";
import BasesSpanish from "./BasesSpanish";
import BasesEnglish from "./BasesEnglish";
import { useEffect, useState } from "react";
import CookiesPageEnglish from "./CookiesPageEnglish";
import { trackPageMatomo } from "./utils/matomoUtils";
// import LandingPC from "./LandingPC";
import { isMobile } from "react-device-detect";
//@ts-ignore
import useScreenOrientation from "react-hook-screen-orientation";
import Orientation from "./Orientation";
import ArMobile from "./ArMobile";
import ArPc from "./ArPc";
import LandingPC from "./LandingPC";

export default function App() {
  const location = useLocation();
  const cookies = CookiesStore((state) => state.cookies);
  const analyticsCookies = CookiesStore((state) => state.analyticsCookie);
  const [exp, setExp] = useState<any>(null);
  //const preferencesCookie = CookiesStore((state) => state.preferencesCookie);

  if (!cookies) {
    const cookies = Cookies.get();
    for (const n in cookies) {
      Cookies.remove(n);
    }
  }
  if (!analyticsCookies) {
    Cookies.remove(NAME_COOKIES.ANALYTICS);
  }

  useEffect(() => {
    trackPageMatomo(location[0]);
  }, []);

  useEffect(() => {
    var expText = new URLSearchParams(window.location.search).get("exp");
    if (expText !== null) {
      setExp(expText);
    }
  }, []);

  return (
    <div
      className={`w-full h-full select-none ${
        !cookies && location[0] !== APP_ROUTES.COOKIES
          ? "overflow-y-hidden"
          : ""
      }`}
    >
      {!cookies && location[0] !== APP_ROUTES.COOKIES && <CookiesBanner />}
      <Route path={APP_ROUTES.HOME}>
        {/* {exp !== null ? (
          <ArPc />
        ) : (
          <>
            {window.innerHeight > window.innerWidth ? (
              <LandingVirtualSpace express={false} embededVersion={false} />
            ) : isMobile ? (
              <LandingVirtualSpace express={false} embededVersion={false} />
            ) : (
              <LandingPC />
            )}
          </>
        )} */}
        {window.innerHeight > window.innerWidth ? (
              <LandingVirtualSpace express={false} embededVersion={false} />
            ) : isMobile ? (
              <LandingVirtualSpace express={false} embededVersion={false} />
            ) : (
              <LandingPC />
            )}
      </Route>
      <Route path={APP_ROUTES.EXPRESS}>
        <LandingVirtualSpace express={true} embededVersion={false} />
      </Route>
      <Route path={APP_ROUTES.BASES}>
        {i18n.language.startsWith("es") ? <BasesSpanish /> : <BasesEnglish />}
      </Route>
      <Route path={APP_ROUTES.PRIVACIDAD}>
        {i18n.language.startsWith("es") ? (
          <PrivacyPageSpanish />
        ) : (
          <PrivacyPageEnglish />
        )}
      </Route>
      <Route path={APP_ROUTES.COOKIES}>
        {i18n.language.startsWith("es") ? (
          <CookiesPageSpanish />
        ) : (
          <CookiesPageEnglish />
        )}
      </Route>
      <Route path={APP_ROUTES.PC}>
        <LandingVirtualSpace express={false} embededVersion={true} />
      </Route>

      <Route path={APP_ROUTES.PC_AR}>
        <ArMobile qr={exp} />
      </Route>
    </div>
  );
}
