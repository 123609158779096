import {useState} from "react";
import {FaChevronRight, FaChevronDown} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import Switch from "react-switch";
import {CSSTransition} from "react-transition-group";

interface ICookiesAdvancedOptionsProps {
  analyticsSelected: boolean;
  setAnalyticsSelected: (value: boolean) => void;
  preferencesSelected: boolean;
  setPreferencesSelected: (value: boolean) => void;
}

const CookiesAdvancedOptions = ({
  preferencesSelected,
  setPreferencesSelected,
  analyticsSelected,
  setAnalyticsSelected,
}: ICookiesAdvancedOptionsProps) => {
  const {t} = useTranslation();

  const [infoNecesaryOpen, setInfoNecesaryOpen] = useState(false);
  const [infoAnalyticsOpen, setInfoAnalyticsOpen] = useState(false);
  const [infoPreferencesOpen, setInfoPreferencesOpen] = useState(false);

  const [isConfigurationChanged, setConfigurationChanged] = useState(false);

  return (
    <div className="flex flex-col my-4">
      <div
        className="flex items-center justify-between p-2 my-1 rounded-lg"
        style={{backgroundColor: "#4891dc"}}
      >
        <div className="flex items-center justify-center">
          {!infoNecesaryOpen ? (
            <span>
              <FaChevronRight
                className="mr-2 cursor-pointer"
                onClick={() => {
                  if (infoAnalyticsOpen) setInfoAnalyticsOpen(false);
                  setInfoNecesaryOpen(true);
                }}
              />{" "}
            </span>
          ) : (
            <span>
              <FaChevronDown
                className="mr-2 cursor-pointer"
                onClick={() => setInfoNecesaryOpen(false)}
              />{" "}
            </span>
          )}
          <span>{t("necessary-cookies")}</span>
        </div>
        <div className="flex items-center">
          {" "}
          <span>{t("always-enabled")}</span>
        </div>
      </div>
      <CSSTransition
        in={infoNecesaryOpen}
        timeout={300}
        classNames="necesary-info-text"
        unmountOnExit
      >
        <div className="p-2 text-xs">
          <p>{t("necessary-cookies-text")}</p>
          <div className="bg-gray-100 text-black p-2 my-4">
            <div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">cookie_consent</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("1-year")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">{t("cookie_consent-text")}</div>
              </div>
            </div>
            <div className="mt-4">
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">mtm_cookie_consent</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("30-years")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">
                  {t("mtm_cookie_consent-cookie-text")}
                </div>
              </div>
            </div>
          </div>
          {/* <table className="w-full my-6 table-auto">
            <thead>
              <tr className="bg-gray-400 text-black">
                <th className="p-2">Cookie</th>
                <th className="p-2">Duración</th>
                <th className="p-2">Descripción</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-100 text-black">
                <td className="p-2">cookie_consent</td>
                <td className="p-2">{t("1-year")}</td>
                <td className="p-2">{t("cookie_consent-text")}</td>
              </tr>
              <tr className="bg-gray-200 text-black">
                <td className="p-2">an_cookie_consent</td>
                <td className="p-2">{t("1-year")}</td>
                <td className="p-2">{t("an_cookie_consent-text")}</td>
              </tr>
            </tbody>
          </table>{" "} */}
        </div>
      </CSSTransition>
      <div
        className="flex items-center justify-between p-2 my-1 rounded-lg"
        style={{backgroundColor: "#4891dc"}}
      >
        <div className="flex items-center justify-center">
          {!infoAnalyticsOpen ? (
            <span>
              <FaChevronRight
                className="mr-2 cursor-pointer"
                onClick={() => {
                  if (infoNecesaryOpen) setInfoNecesaryOpen(false);
                  if (infoPreferencesOpen) setInfoPreferencesOpen(false);
                  setInfoAnalyticsOpen(true);
                }}
              />{" "}
            </span>
          ) : (
            <span>
              <FaChevronDown
                className="mr-2 cursor-pointer"
                onClick={() => setInfoAnalyticsOpen(false)}
              />{" "}
            </span>
          )}
          <span>{t("analytics-cookies")}</span>
        </div>
        <div className="flex items-center">
          {" "}
          {analyticsSelected ? (
            <span className="mr-2">{t("enabled")}</span>
          ) : (
            <span className="mr-2">{t("disabled")}</span>
          )}
          <Switch
            onChange={() => {
              setAnalyticsSelected(!analyticsSelected);
              if (!isConfigurationChanged) setConfigurationChanged(true);
            }}
            checked={analyticsSelected}
            height={24}
          />
        </div>
      </div>{" "}
      <CSSTransition
        in={infoAnalyticsOpen}
        timeout={300}
        classNames="necesary-info-text"
        unmountOnExit
      >
        <div className="p-2 text-xs">
          <p className="my-2">{t("analytics-cookies-text")}</p>
          <div className="bg-gray-100 text-black p-2">
            <div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">_pk_id</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("13-months")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">{t("_pk_id-cookie-text")}</div>
              </div>
            </div>
            <div className="mt-4">
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">_pk_ses</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("30-minutes")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">{t("_pk_ses-cookie-text")}</div>
              </div>
            </div>
          </div>
          {/* <table className="w-full my-6 table-auto">
            <thead>
              <tr className="bg-gray-400 text-black">
                <th className="p-2">Cookie</th>
                <th className="p-2">Duración</th>
                <th className="p-2">Descripción</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-100 text-black">
                <td className="p-2">_pk_id</td>
                <td className="p-2">{t("13-months")}</td>
                <td className="p-2">{t("_pk_id-cookie-text")}</td>
              </tr>
              <tr className="bg-gray-200 text-black">
                <td className="p-2">_pk_ses</td>
                <td className="p-2">{t("30-minutes")}</td>
                <td className="p-2">{t("_pk_ses-cookie-text")}</td>
              </tr>
              <tr className="bg-gray-100 text-black">
                <td className="p-2">mtm_cookie_consent</td>
                <td className="p-2">{t("30-years")}</td>
                <td className="p-2">{t("mtm_cookie_consent-cookie-text")}</td>
              </tr>
            </tbody>
          </table> */}
        </div>
      </CSSTransition>
      {/* <div
        className="flex items-center justify-between p-2 my-1 rounded-lg"
        style={{backgroundColor: "#4891dc"}}
      >
        <div className="flex items-center justify-center">
          {!infoPreferencesOpen ? (
            <span>
              <FaChevronRight
                className="mr-2 cursor-pointer"
                onClick={() => {
                  if (infoNecesaryOpen) setInfoNecesaryOpen(false);
                  if (infoAnalyticsOpen) setInfoAnalyticsOpen(false);

                  setInfoPreferencesOpen(true);
                }}
              />{" "}
            </span>
          ) : (
            <span>
              <FaChevronDown
                className="mr-2 cursor-pointer"
                onClick={() => setInfoPreferencesOpen(false)}
              />{" "}
            </span>
          )}
          <span>{t("preference-cookies")}</span>
        </div>
        <div className="flex items-center">
          {" "}
          {preferencesSelected ? (
            <span className="mr-2">{t("enabled")}</span>
          ) : (
            <span className="mr-2">{t("disabled")}</span>
          )}
          <Switch
            onChange={() => {
              setPreferencesSelected(!preferencesSelected);
              if (!isConfigurationChanged) setConfigurationChanged(true);
            }}
            checked={preferencesSelected}
            height={24}
          />
        </div>
      </div>{" "}
      <CSSTransition
        in={infoPreferencesOpen}
        timeout={300}
        classNames="necesary-info-text"
        unmountOnExit
      >
        <div className="p-2 text-xs">
          <p className="my-2">{t("preferences-cookie-text")}</p>

          <table className="w-full my-6 table-auto">
            <thead>
              <tr className="bg-gray-400 text-black">
                <th className="p-2">Cookie</th>
                <th className="p-2">{t("duration")}</th>
                <th className="p-2">{t("description")}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-100 text-black">
                <td className="p-2">cookie_language</td>
                <td className="p-2">{t("1-year")}</td>
                <td className="p-2">{t("_pk_id-cookie-text")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CSSTransition> */}
    </div>
  );
};

export default CookiesAdvancedOptions;
