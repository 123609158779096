import React, { useEffect } from "react";
import { Link } from "wouter";
import { useTranslation } from "react-i18next";
import HeaderCookiesPrivacy from "./HeaderCookiesPrivacy";
import { APP_ROUTES } from "./utils/utils";

const CookiesPageSpanish = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <HeaderCookiesPrivacy />
      <div className="px-4 py-8 bg-white">
        <h1 className="text-cookiesBlue uppercase text-xl font-latoBold">
          POLÍTICA DE COOKIES
        </h1>
        <div className="py-4 font-gatterSansRegular text-sm">
          <h2 className="mt-6 mb-2 font-latoBold">¿QUÉ SON LAS COOKIES?</h2>
          <p>
            Este sitio web utiliza cookies y/o tecnologías similares que
            almacenan y recuperan información cuando navegas. En general, estas
            tecnologías pueden servir para finalidades muy diversas, como, por
            ejemplo, reconocerte como usuario/a, obtener información sobre tus
            hábitos de navegación, o personalizar la forma en que se muestra el
            contenido. Los usos concretos que hacemos de estas tecnologías se
            describen a continuación.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            ¿QUÉ TIPOS DE COOKIES SE UTILIZAN EN ESTA PÁGINA WEB?
          </h2>
          <p>
            a) Cookies técnicas: son aquellas que permiten al usuario/a la
            navegación a través de la página web, plataforma o aplicación y la
            utilización de las diferentes opciones o servicios que en ella
            existen, incluyendo aquellas que se utilizan para permitir la
            gestión y operativa de la página web y habilitar sus funciones y
            servicios, como, por ejemplo, controlar el tráfico y la comunicación
            de datos, identificar la sesión, acceder a partes de acceso
            restringido, etc.
            <br />
            b) Cookies de análisis o medición: son aquellas que, tratadas por
            nosotros o por terceros, nos permiten cuantificar el número de
            usuarios/as y así realizar la medición y análisis estadístico de la
            utilización que hacen los/las usuarios/as del servicio ofertado.
            Para ello se analiza su navegación en nuestra página web con el fin
            de mejorar la oferta de productos o servicios que le ofrecemos.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            ¿QUIÉN UTILIZA LAS COOKIES?
          </h2>
          <p>
            a) Cookies propias: son aquellas que se envían al equipo terminal
            del usuario/a desde un equipo o dominio gestionado por el propio
            responsable de la web y desde el que se presta el servicio
            solicitado por el/la usuario/a.
            <br />
            b) Cookies de tercero: son aquellas que se envían al equipo terminal
            del usuario/a desde un equipo o dominio que no es gestionado por el
            responsable de la web, sino por otra entidad que trata los datos
            obtenidos través de las cookies.
            <br />
            <br />
            {/* Esta página web utiliza Google Analytics, un servicio analítico de
            web prestado por Google, Inc., una compañía de Delaware cuya oficina
            principal está en 1600 Amphitheatre Parkway, Mountain View
            (California), CA 94043, Estados Unidos (“Google”). Google Analytics
            utiliza “cookies” para ayudar al website a analizar el uso que hacen
            los usuarios del sitio web. La información que genera la cookie
            acerca de su uso del website (incluyendo su dirección IP) será
            directamente transmitida y archivada por Google en los servidores de
            Estados Unidos. Google usará esta información por cuenta nuestra con
            el propósito de seguir la pista de su uso del website, recopilando
            informes de la actividad del website y prestando otros servicios
            relacionados con la actividad del website y el uso de Internet.
            Google no asociará su dirección IP con ningún otro dato del que
            disponga Google.
            <br />
            Puede consultar el Uso de las cookies de Google Analytics en sitios
            web
            <a
              href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es-419"
              target="_blank"
            >
              AQUÍ
            </a> */}
            Esta página web utiliza Matomo Analytics, un servicio analítico de
            web prestado por Matomo, una compañía de Nueva Zelanda cuya oficina
            principal está en 150 Willis, Wellington, Wellington 6011,NZ. Matomo
            Analytics utiliza “cookies” para ayudar al website a analizar el uso
            que hacen los usuarios del sitio web. La información que generan la
            cookies acerca de su uso del website será directamente archivada en
            nuestros propios servidores. Usaremos esta información con el
            propósito de seguir la pista de su uso del website y recopilando
            informes de la actividad del website. Si está de acuerdo con las
            cookies de Matomo, se almacenarán los siguientes datos:
            <ul className="my-4 list-inside">
              <li className="list-disc">
                Dirección IP acortada y nombre del dispositivo con el que accede
                al website.
              </li>
              <li className="list-disc">
                Nombre, idioma y versión de su navegador
              </li>
              <li className="list-disc">Sistema operativo</li>
              <li className="list-disc">La URL de la página visitada</li>
              <li className="list-disc">Fecha y hora de acceso</li>
            </ul>
            Puede consultar el Uso de las cookies de Matomo Analytics en sitios
            web{" "}
            <a
              href="https://matomo.org/faq/general/faq_146/"
              className="text-cookiesBlue underline font-bold"
            >
              AQUÍ
            </a>
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            COOKIES UTILIZADAS EN ESTE SITIO WEB
          </h2>
          <p>
            A continuación se identifican las cookies que están siendo
            utilizadas en este portal así como su tipología y función:
          </p>
          <div className="bg-gray-100 text-black p-2 mt-4">
            {" "}
            <div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">cookie_consent</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("1-year")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">{t("cookie_consent-text")}</div>
              </div>
            </div>
            <div className="mt-4">
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">mtm_cookie_consent</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("30-years")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">
                  {t("mtm_cookie_consent-cookie-text")}
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">_pk_id</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("13-months")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">{t("_pk_id-cookie-text")}</div>
              </div>
            </div>
            <div className="mt-4">
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">_pk_ses</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("30-minutes")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">{t("_pk_ses-cookie-text")}</div>
              </div>
            </div>
          </div>
          <h2 className="mt-6 mb-2 font-latoBold">
            ¿CÓMO PUEDE ACEPTAR, DENEGAR, REVOCAR EL CONSENTIMIENTO O ELIMINAR
            LAS COOKIES?
          </h2>
          <p>
            Tienes la opción de permitir, bloquear o eliminar las cookies
            instaladas en tu equipo mediante la configuración de las opciones
            del navegador instalado en él. Al desactivar cookies, algunos de los
            servicios disponibles podrían dejar de estar operativos. La forma de
            deshabilitar las cookies es diferente para cada navegador, pero
            normalmente puede hacerse desde el menú Herramientas u Opciones.
            También puede consultarse el menú de Ayuda del navegador dónde
            puedes encontrar instrucciones. La persona usuaria podrá en
            cualquier momento elegir qué cookies quiere que funcionen en este
            sitio web.
            <br />
            Puedes permitir, bloquear o eliminar las cookies instaladas en tu
            equipo mediante la configuración de las opciones del navegador
            instalado en tu ordenador:
          </p>
          <ul className="mt-4 list-inside">
            <li className="list-disc">
              <a
                href="https://support.google.com/chrome/answer/95647?hl=es"
                className="text-cookiesBlue underline font-bold"
              >
                Google Chrome
              </a>
            </li>
            <li className="list-disc">
              <a
                className="text-cookiesBlue underline font-bold"
                href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es"
              >
                Mozilla Firefox
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                className="text-cookiesBlue underline font-bold"
              >
                Internet Explorer
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://support.apple.com/kb/ph5042?locale=en_US&viewlocale=es_es"
                className="text-cookiesBlue underline font-bold"
              >
                Safari
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://help.opera.com/en/latest/web-preferences/#cookies"
                className="text-cookiesBlue underline font-bold"
              >
                Opera
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://support.microsoft.com/en-us/windows/windows-phone-7-3ebc303c-59c0-d367-3995-f258b184fabb"
                className="text-cookiesBlue underline font-bold"
              >
                Internet Explorer (Windows Phone)
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://support.apple.com/en-us/HT201265"
                className="text-cookiesBlue underline font-bold"
              >
                Safari IOS (iPhone y iPad)
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://support.google.com/chrome/answer/114662?hl=es&visit_id=637997831359600642-1061789918&rd=1"
                className="text-cookiesBlue underline font-bold"
              >
                Chrome Android
              </a>
            </li>
          </ul>
          <h2 className="mt-6 mb-2 font-latoBold">
            TRANSFERENCIAS DE DATOS A TERCEROS PAÍSES
          </h2>
          <p>
            Puedes informarte de las transferencias a terceros países que, en su
            caso, realizan los terceros identificados en esta política de
            cookies en sus correspondientes políticas (ver los enlaces
            facilitados en el apartado “Cookies de terceros”).
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">ELABORACIÓN DE PERFILES</h2>
          <p>
            No se elaborará un perfil de usuario basado en la monitorización de
            tus actividades de navegación en nuestro Sitio Web. Y en
            consecuencia, no se tomarán decisiones automatizadas basadas en
            dicha navegación.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            PERIODO DE CONSERVACIÓN DE LAS COOKIES
          </h2>
          <p>
            a) Cookies de sesión: expiran automáticamente cuando el usuario
            termina la sesión y suelen emplearse para conservar información
            necesaria mientras se presta un servicio solicitado por el usuario
            en una sola ocasión
            <br />
            b) Cookies persistentes: permanecen almacenadas en el terminal del
            usuario durante un periodo determinado (desde unos minutos a varios
            años).
          </p>{" "}
          <h2 className="mt-6 mb-2 font-latoBold">
            MÁS INFORMACIÓN SOBRE TRATAMIENTO DE DATOS
          </h2>
          <p className="mt-4">
            Visite nuestra{" "}
            <Link
              to={APP_ROUTES.PRIVACIDAD}
              className="text-cookiesBlue underline font-bold"
            >
              Política de Privacidad
            </Link>
          </p>{" "}
        </div>
      </div>
    </div>
  );
};

export default CookiesPageSpanish;
