export const activateCookies = () => {
  try {
    window._paq.push(["rememberCookieConsentGiven"]);
  } catch (err) {
    console.error(err);
  }
};

export const sendMatomoEvent = (category, action, name = "") => {
  //console.log("SEND EVENT", `Cat: ${category}`, `Action: ${action}`, name);
  try {
    window._paq.push(["trackEvent", category, action, name, 1]);
  } catch (err) {
    console.error(err);
  }
};

export const trackPageMatomo = (pageTitle = "") => {
  try {
    if (pageTitle)
      window._paq.push([
        "setDocumentTitle",
        `${document.title} ${pageTitle !== "/" ? pageTitle : ""}`,
      ]);
    window._paq.push(["trackPageView"]);
  } catch (err) {
    console.error(err);
  }
};
