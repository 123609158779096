import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "wouter";
import HeaderCookiesPrivacy from "./HeaderCookiesPrivacy";
import { APP_ROUTES } from "./utils/utils";

const CookiesPageEnglish = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <HeaderCookiesPrivacy />
      <div className="px-4 py-8 bg-white">
        <h1 className="text-cookiesBlue uppercase text-xl font-latoBold">
          Cookies policy
        </h1>
        <div className="py-4 font-gatterSansRegular text-sm">
          <h2 className="mt-6 mb-2 font-latoBold">WHAT ARE COOKIES?</h2>
          <p>
            This website uses cookies and / or similar technologies that store
            and retrieve information when you browse. In general, these
            technologies can serve very different purposes, such as, for
            example, recognizing you as a user, obtaining information about your
            browsing habits, or customizing the way the content is displayed.
            The specific uses we make of these technologies are described below.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            WHAT TYPES OF COOKIES ARE USED ON THIS WEBSITE?
          </h2>
          <p>
            a) Technical cookies: are those that allow the user to navigate
            through the website, platform or application and the use of the
            different options or services that exist in it, including those that
            are used to allow the management and operation of the website and
            enable its functions and services, such as, for example, controlling
            traffic and data communication, identifying the session, accessing
            parts of restricted access, etc.
            <br />
            b) Analysis or measurement cookies : are those that, processed by us
            or by third parties, allow us to quantify the number of users and
            thus carry out the measurement and statistical analysis of the use
            that users make of the service offered. For this, your browsing on
            our website is analyzed in order to improve the offer of products or
            services that we offer you.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">WHO USES COOKIES?</h2>
          <p>
            a) Own cookies: are those that are sent to the user's terminal
            equipment from a computer or domain managed by the person
            responsible for the website and from which the service requested by
            the user is provided.
            <br />
            b) b) Third-party cookies: are those that are sent to the user's
            terminal equipment from a computer or domain that is not managed by
            the person responsible for the website, but by another entity that
            processes the data obtained through cookies.
            <br />
            <br />
            {/* This website uses Google Analytics , a web analytics service
            provided by Google, Inc., a Delaware company whose main office is at
            1600 Amphitheater Parkway, Mountain View (California), CA 94043,
            United States (“Google”). Google Analytics uses "cookies" to help
            the website analyze how users use the website. The information
            generated by the cookie about your use of the website (including
            your IP address) will be directly transmitted and filed by Google on
            servers in the United States. Google will use this information on
            our behalf in order to keep track of your use of the website,
            compiling reports on website activity and providing other services
            related to website activity and Internet use. Google will not
            associate your IP address with any other data held by Google.
            <br />
            You can consult the Use of Google Analytics cookies on websites
            <a
              href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es-419"
              target="_blank"
            >
              HERE
            </a> */}
            This website uses Matomo Analytics, a web analytics service provided
            by Matomo, a New Zealand company whose head office is at 150 Willis,
            Wellington, Wellington 6011,NZ. Matomo Analytics uses cookies to
            help the website analyze how users use the website. The information
            generated by cookies about your use of the website will be stored
            directly on our own servers. We will use this information for the
            purpose of tracking your use of the website and compiling reports on
            website activity. If you agree to Matomo's cookies, the following
            data will be stored:{" "}
            <ul className="my-4 list-inside">
              <li className="list-disc">
                Shortened IP address and name of the device with which you
                access the website.
              </li>
              <li className="list-disc">
                Name, language and version of your browser
              </li>
              <li className="list-disc">Operating system</li>
              <li className="list-disc">The URL of the page visited</li>
              <li className="list-disc">Date and time of access</li>
            </ul>{" "}
            You can view the Use of Matomo Analytics cookies on websites{" "}
            <a
              href="https://matomo.org/faq/general/faq_146/"
              className="text-cookiesBlue underline font-bold"
            >
              HERE
            </a>
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            COOKIES USED ON THIS WEBSITE
          </h2>
          <p>
            The cookies that are being used in this portal are identified below,
            as well as their type and function:
          </p>
          <div className="bg-gray-100 text-black p-2 mt-4">
            {" "}
            <div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">cookie_consent</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("1-year")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">{t("cookie_consent-text")}</div>
              </div>
            </div>
            <div className="mt-4">
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">mtm_cookie_consent</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("30-years")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">
                  {t("mtm_cookie_consent-cookie-text")}
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">_pk_id</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("13-months")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">{t("_pk_id-cookie-text")}</div>
              </div>
            </div>
            <div className="mt-4">
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>Cookie:</strong>
                </div>
                <div className="w-2/3">_pk_ses</div>
              </div>
              <div className="py1 flex">
                <div className="w-1/3">
                  <strong>{`${t("duration")}:`}</strong>
                </div>
                <div className="w-2/3">{t("30-minutes")}</div>
              </div>
              <div className="py-1 flex">
                <div className="w-1/3">
                  <strong>{`${t("description")}:`}</strong>
                </div>
                <div className="w-2/3">{t("_pk_ses-cookie-text")}</div>
              </div>
            </div>
          </div>
          <h2 className="mt-6 mb-2 font-latoBold">
            HOW YOU CAN ACCEPT, DENY, REVOKE, CONSENT OR DELETE COOKIES?
          </h2>
          <p>
            You have the option to allow, block or delete the cookies installed
            on your computer by configuring the options of the browser installed
            on it. By deactivating cookies, some of the available services may
            cease to be operational. The way to disable cookies is different for
            each browser, but it can usually be done from the Tools or Options
            menu. You can also consult the Help menu of the browser where you
            can find instructions. The user may at any time choose which cookies
            he wants to work on this website.
            <br />
            You can allow, block or delete the cookies installed on your
            computer by configuring the options of the browser installed on your
            computer:
          </p>
          <ul className="mt-4 list-inside">
            <li className="list-disc">
              <a
                href="https://support.google.com/chrome/answer/95647?hl=es"
                className="text-cookiesBlue underline font-bold"
              >
                Google Chrome
              </a>
            </li>
            <li className="list-disc">
              <a
                className="text-cookiesBlue underline font-bold"
                href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es"
              >
                Mozilla Firefox
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                className="text-cookiesBlue underline font-bold"
              >
                Internet Explorer
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://support.apple.com/kb/ph5042?locale=en_US&viewlocale=es_es"
                className="text-cookiesBlue underline font-bold"
              >
                Safari
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://help.opera.com/en/latest/web-preferences/#cookies"
                className="text-cookiesBlue underline font-bold"
              >
                Opera
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://support.microsoft.com/en-us/windows/windows-phone-7-3ebc303c-59c0-d367-3995-f258b184fabb"
                className="text-cookiesBlue underline font-bold"
              >
                Internet Explorer (Windows Phone)
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://support.apple.com/en-us/HT201265"
                className="text-cookiesBlue underline font-bold"
              >
                Safari IOS (iPhone y iPad)
              </a>
            </li>
            <li className="list-disc">
              <a
                href="https://support.google.com/chrome/answer/114662?hl=es&visit_id=637997831359600642-1061789918&rd=1"
                className="text-cookiesBlue underline font-bold"
              >
                Chrome Android
              </a>
            </li>
          </ul>
          <h2 className="mt-6 mb-2 font-latoBold">
            DATA TRANSFERS TO THIRD COUNTRIES
          </h2>
          <p>
            You can find out about transfers to third countries that, where
            appropriate, are made by the third parties identified in this cookie
            policy in their corresponding policies (see the links provided in
            the "Third Party Cookies" section).
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">PROFILING</h2>
          <p>
            A user profile will not be created based on the monitoring of your
            browsing activities on our Website. And consequently, no automated
            decisions will be made based on such browsing.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">COOKIE RETENTION PERIOD</h2>
          <p>
            a) Session cookies: they expire automatically when the user ends the
            session and are usually used to keep necessary information while a
            service requested by the user is provided on a single occasion
            <br />
            b) Persistent cookies: they remain stored in the user's terminal for
            a certain period (from a few minutes to several years).
          </p>{" "}
          <h2 className="mt-6 mb-2 font-latoBold">
            MORE INFORMATION ON DATA PROCESSING
          </h2>
          <p className="mt-4">
            Visit our{" "}
            <Link
              to={APP_ROUTES.PRIVACIDAD}
              className="text-cookiesBlue underline font-bold"
            >
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookiesPageEnglish;
