import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const resources = {
  en: {
    translation: {
      LANDING_TITLE_1: "The Pilares",
      LANDING_TITLE_2: "are also",
      LANDING_TITLE_3: "digital",
      LANDING_TITLE_TABLET_1: "The pilares are also",
      LANDING_TITLE_TABLET_2: "digital",
      AR_1: "AUGMENTED REALITY",
      AR_2: "New!",
      AR_3: "Create videos with the animated effects that are scattered around the city and upload them to social networks to win prizes.",
      AR_4: "The event has already ended.",
      AR_5: "The flower route",
      EXPRESS_1: "FLOWER OFFERING",
      EXPRESS_2: "Virtual Offering 2023",
      EXPRESS_3:
        "Fast and without rows. Design a digital greeting and send it to your people.",
      EXPRESS_4: "Virtual Offering 2023",
      EXPRESS_5: "Soon",
      PLAZA_PILAR_1: "VIRTUAL SPACE 3D",
      PLAZA_PILAR_2: "Plaza del pilar",
      PLAZA_PILAR_3:
        "Our classic. Put on a big head. Launch a rocket. Dance in front of the stage.",
      PLAZA_PILAR_4: "Plaza del pilar",
      PLAZA_PILAR_5: "Soon",
      BUSCANOS_REDES: "Find us on networks",
      NO_TE_PIERDAS: "Don't miss anything",
      AGENDA_CULTURAL: "Cultural agenda",
      BOLETIN: "Newsletter",
      POLITICA_PRIVACIDAD: "PRIVACY POLICY",
      POLITICA_COOKIES: "COOKIES POLICY",
      EXP1: "Access with your",
      EXP2: "smartphone",
      EXP3: "to",
      EXP4: "see the effects",
      EXP5: "Scan the QR with your mobile to access",
      EXP6: "Or choose another option:",
      EXP7: "Virtual Offering",
      EXP8: "Plaza del Pilar 3D",
      gotoenglish: "SWITCH TO SPANISH VERSION",
      ofrendaTitle: "VIRTUAL FLOWER OFFERING",
      ofrendahecha: "OFFERING MADE!",
      comenzar: "START",
      pilar23: "#PILAR23",
      personaliza: "Customize your offering",
      juegos: "GAMES",
      juegosDesc: "Play and enjoy El Pilar",
      descubremas: "DISCOVER MORE",
      eligeofrenda: "Choose your offering",
      colocando: "Placing your offering in the structure...",
      uneteaungrupo: "Join a group",
      hacerofrenda: "MAKE THE OFFERING",
      nohaselegidogrupo: "Aún no has elegido un grupo",
      buscar: "SEARCH",
      cambiar: "CHANGE",
      donequieres: "Where do you want to place your offering?",
      ofrendaengrupo: "OFFERING IN GROUP",
      eligegrupounion: "Choose a group to join:",
      siguiente: "NEXT",
      atras: "BACK",
      opcional: "(Optional)",
      buscargrupo: "Search group...",
      aceptar: "ACCEPT",
      cancelar: "CANCEL",
      escribetumensaje: "WRITE YOUR MESSAGE",
      anadirgrupo: "SELECT GROUP",
      personalizavirtual: "Customize your virtual bouquet",
      grupo: "Group:",
      ofendaHecha: "YOU HAVE MADE THE ",
      ofendaHecha2: "OFFERING",
      comparte: "Share your offering",
      novedades: "NEWS",
      guardar: "SAVE",
      compartir: "SHARE",
      sigueexplorando: "Continue exploring...",
      notepierdas: "Don't miss el Pilar Virtual in 3D",
      iralpilar: "GO TO EL PILAR 3D",
      ofrendarapida: "EXPRESS OFFERING",
      sinfilas: "Your offering, without queues",
      personajes: "3D CHARACTERS",
      eligeavatar: "Choose your avatar and explore La Plaza",
      asistente: "VIRTUAL ASSISTANT",
      preguntaapilara: "Ask la Pilara!",
      agenda: "CULTURAL AGENDA",
      descargapdf: "DOWNLOAD PDF",
      echaunojo: "Take a look at the scheduled events",
      notepierdas: "DON'T MISS A THING",
      apuntate: "SUBSCRIBE TO THE NEWSLETTER",
      estamosenredes: "WE ARE IN THE NETWORKS",
      politicaprivacidad: "PRIVACY POLICY",
      politicacookies: "COOKIES POLICY",
      tocaparaescribir: "WRITE YOUR DEDICATION",
      tugrupo: "¡YOUR GROUP HAS",
      ofrendas: "OFFERINGS!",
      days: "DAYS",
      hours: "HOURS",
      minutes: "MINUTES",
      seconds: "SECONDS",
      ofering1: "Your group goes on",
      ofering2: "with ",
      ofering3: "offerings!",
      comienza: "START IN:",
      participar: "PARTICIPATE",
      quieresparticipar: "Do you want to enter the raffle for a trip?",
      quieresparticiparZaragoza:
        "Do you want to enter the raffle for a trip to Zaragoza?",
      twittea: "Tweet this code with the hashtag #VenAlaOfrenda",
      basesconcurso: "RAFFLE RULES...",
      "cookies-title": "Information about the cookies used",
      "cookies-message":
        "We use our own and third party cookies to provide our services and collect statistical services and collect statistical information. You can obtain more information about cookies, or learn how to change your cookie settings at the following link.",
      "cookies-policy": "Cookies policy",
      "setup-cookies": "Set up",
      "accept-cookies": "Accept all",
      "necessary-cookies": "Necessary",
      "analytics-cookies": "Analytics",
      "preference-cookis": "Preference cookies",
      "save-selected-cookies": "Save selected",
      "always-enabled": "Always enabled",
      "advanced-configuration": "Advanced configuration",
      "accept-all-cookies": "Accept all",
      "save-cookies-configuration": "Save configuration",
      "necessary-cookies-text":
        "Necessary cookies are required to enable the basic features of this site, such as adjusting your consent preferences. These cookies do not store any personally identifiable data.",
      "analytics-cookies-text":
        "They help us improve our website. They collect anonymous information about how visitors use our website and collect information about the most visited pages on the site. They let us know what error messages were displayed.",
      "marketing-cookies-text":
        "They allow displaying offers relevant to the visitor's interests. They display personalized ads anonymously.",
      "cookie_consent-text":
        "This cookie is used to know if the user has saved his/her cookie settings and to show the acceptance banner.",
      "an_cookie_consent-text":
        "This cookie is used to store whether the user has accepted analytical cookies.",
      "_gat_gtag-cookie-text":
        "Cookie used by Google to add the Google Analytics tracking code from Google Tag Manager.",
      "_ga-cookie-text":
        "This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session, camapign data and keep track of site usage for the site's analytics report. The cookies store information anonymously and assigns a randoly generated number to identify unique visitors.",
      "_gid-cookie-text":
        "This cookie is set by Google Analytics. The cookie is used to store information about how visitors use a website and helps create an analytics report on how the website is performing. The data collected, including the number of visitors, the source from which they came and the pages, appeared in an anonymous form.",
      "uuid-cookie-text":
        "This cookie is used to optimize ad relevance by collecting visitor data from multiple websites; this exchange of visitor data is typically provided by a third-party data center or ad exchange.",
      "sib_cuid-cookie-text":
        "They allow displaying offers relevant to the visitor's interests. They display personalized ads anonymously.Recoge información sobre la navegación y las preferencias del usuario en el sitio web, que se utiliza para enviar posibles boletines informativos basados en esta información.",
      "1-year": "1 year",
      "1-minute": "1 minute",
      "2-years": "2 years",
      "1-day": "1 day",
      "13-months": "13 months",
      "30-minutes": "30-minutes",
      "30-years": "30 years",
      duration: "Duration",
      description: "Description",
      enabled: "Enabled",
      disabled: "Disabled",
      analytics: "Analytics",
      "_pk_id-cookie-text":
        "Used to store a few details about the user such as the unique visitor ID.",
      "_pk_ses-cookie-text": "Used to temporarily store data for the visit.",
      "mtm_cookie_consent-cookie-text":
        "It is used to remember that the user has consented to the storage and use of Matomo cookies.",
      "preferences-cookie-text":
        "Preference cookies allow a website to remember information that changes the behavior or appearance of the website, such as your preferred language or the region you are in.",
    },
  },
  es: {
    translation: {
      LANDING_TITLE_1: "Los pilares",
      LANDING_TITLE_2: "también son",
      LANDING_TITLE_3: "digitales",
      LANDING_TITLE_TABLET_1: "Los pilares también son",
      LANDING_TITLE_TABLET_2: "digitales",
      AR_1: "REALIDAD AUMENTADA",
      AR_2: "Nuevo!",
      AR_3: "Crea vídeos con los efectos animados que hay repartidos por la ciudad y súbelos a las redes para ganar premios.",
      AR_4: "El evento ya ha finalizado.",
      AR_5: "La Ruta de las Flores",
      EXPRESS_1: "OFRENDA DE FLORES",
      EXPRESS_2: "Ofrenda Virtual 2023",
      EXPRESS_3:
        "Rápida y sin filas. Diseña una felicitación digital y envíala a tu gente.",
      EXPRESS_4: "Ofrenda Virtual 2023",
      EXPRESS_5: "Próximamente",
      PLAZA_PILAR_1: "ESPACIO VIRTUAL 3D",
      PLAZA_PILAR_2: "Plaza del Pilar",
      PLAZA_PILAR_3:
        "Nuestro clásico. Ponte un cabezudo. Lanza un cohete. Baila frente al escenario.",
      PLAZA_PILAR_4: "Plaza del Pilar",
      PLAZA_PILAR_5: "Próximamente",
      BUSCANOS_REDES: "Búscanos en redes",
      NO_TE_PIERDAS: "No te pierdas nada",
      AGENDA_CULTURAL: "Agenda cultural",
      BOLETIN: "Apúntate al boletín",
      POLITICA_PRIVACIDAD: "POLÍTICA PRIVACIDAD",
      POLITICA_COOKIES: "POLÍTICA COOKIES",
      EXP1: "Accede con tu",
      EXP2: "smartphone",
      EXP3: "para",
      EXP4: "ver los efectos",
      EXP5: "Escanea el QR con tu móvil para acceder",
      EXP6: "O elige otra opción:",
      EXP7: "Ofrenda Virtual",
      EXP8: "Plaza del Pilar 3D",
      siguiente: "SIGUIENTE",
      atras: "ATRÁS",
      gotoenglish: "IR A LA VERSIÓN INGLESA",
      ofrendaTitle: "OFRENDA DE FLORES VIRTUAL",
      ofrendahecha: "¡OFRENDA HECHA!",
      comenzar: "COMENZAR",
      pilar23: "#PILAR23",
      personalizavirtual: "Personaliza tu ramo virtual",
      personaliza: "Personaliza tu ofenda",
      juegos: "JUEGOS",
      juegosDesc: "Juega y diviértete en la plaza",
      descubremas: "DESCUBRE MÁS",
      eligeofrenda: "Elige tu ofrenda",
      colocando: "Colocando tu ofrenda en la estructura...",
      uneteaungrupo: "Únete a un grupo",
      hacerofrenda: "HACER OFRENDA",
      buscar: "BUSCAR",
      cambiar: "CAMBIAR",
      ofering1: "¡Tu grupo va el ",
      ofering2: "con ",
      ofering3: "ofrendas!",
      opcional: "(Opcional)",
      donequieres: "Elige donde quieres colocar tu ramo",
      ofrendaengrupo: "OFRENDA EN GRUPO",
      eligegrupounion: "Elige un grupo para unirte:",
      buscargrupo: "Buscar grupo...",
      aceptar: "ACEPTAR",
      cancelar: "CANCELAR",
      escribetumensaje: "ESCRIBE TU MENSAJE",
      anadirgrupo: "ELEGIR GRUPO",
      grupo: "Grupo:",
      ofendaHecha: "¡HAS REALIZADO LA ",
      ofendaHecha2: "OFRENDA",
      tugrupo: "¡TU GRUPO SUMA",
      ofrendas: "OFRENDAS!",
      comparte: "Comparte tu ofrenda",
      novedades: "NOVEDADES",
      guardar: "GUARDAR",
      compartir: "COMPARTIR",
      sigueexplorando: "Sigue explorando...",
      notepierdas: "No te pierdas el Pilar Virtual en 3D",
      iralpilar: "IR AL PILAR 3D",
      ofrendarapida: "OFRENDA RÁPIDA",
      sinfilas: "Tu ofrenda, sin filas",
      personajes: "PERSONAJES 3D",
      eligeavatar: "Elige tu avatar y explora la plaza",
      asistente: "ASISTENTE VIRTUAL",
      preguntaapilara: "¡Pregunta a la Pilara!",
      agenda: "AGENDA CULTURAL",
      descargapdf: "DESCARGAR PDF",
      echaunojo: "Echa un ojo a los eventos programados este año",
      apuntate: "APÚNTATE AL BOLETÍN",
      estamosenredes: "ESTAMOS EN LAS REDES",
      politicaprivacidad: "POLÍTICA PRIVACIDAD",
      politicacookies: "POLÍTICA COOKIES",
      tocaparaescribir: "ESCRIBE TU DEDICATORIA",
      days: "DÍAS",
      hours: "HORAS",
      minutes: "MINUTOS",
      seconds: "SEGUNDOS",
      comienza: "COMIENZA EN:",
      participar: "PARTICIPAR",
      quieresparticipar: "¿Quieres entrar en el sorteo de un viaje?",
      nohaselegidogrupo: "Aún no has elegido un grupo",
      quieresparticiparZaragoza:
        "¿Quieres entrar en el sorteo de un viaje a Zaragoza?",
      twittea: "Twitea este código con el hashtag #VenAlaOfrenda",
      basesconcurso: "BASES DEL SORTEO...",
      "cookies-title": "Información acerca de las cookies utilizadas",
      "cookies-message":
        "Utilizamos cookies propias y de terceros para prestar nuestros servicios y recoger información estadística. Puede obtener más información sobre las cookies o aprender a cambiar la configuración de las mismas en el siguiente enlace.",
      "cookies-policy": "Política de cookies",
      "setup-cookies": "Configurar",
      "accept-cookies": "Aceptar todas",
      "necessary-cookies": "Necesarias",
      "analytics-cookies": "Analíticas",
      "preference-cookies": "Cookies de preferencia",
      "save-selected-cookies": "Guardar seleccionadas",
      "always-enabled": "Siempre habilitadas",
      "advanced-configuration": "Configuración avanzada",
      "accept-all-cookies": "Aceptar todas",
      "save-cookies-configuration": "Guardar configuración",
      "cookie_consent-text":
        "Esta cookie se utiliza para saber si el usuario ha guardado su configuración de cookies y mostrarle el banner de aceptación.",
      "necessary-cookies-text":
        "Las cookies necesarias son requeridas para habilitar las características básicas de este sitio, como ajustar sus preferencias de consentimiento. Estas cookies no almacenan ningún dato personal identificable.",
      "analytics-cookies-text":
        "Nos ayudan a mejorar nuestro sitio web. Recopilan información anónima sobre cómo los visitantes utilizan nuestra página web y recaban información acerca de las páginas del sitio más visitadas. Nos permiten conocer los mensajes de error que se mostraron.",
      "marketing-cookies-text":
        "Permiten mostrar ofertas relevantes para los intereses del visitante. Muestran anuncios personalizados de forma anónima.",
      "an_cookie_consent-text":
        "Esta cookie se utiliza para almacenar si el usuario ha aceptado las cookies analíticas.",
      "_gat_gtag-cookie-text":
        "Google Tag Manager. Cookie utilizada por Google para añadir el código de seguimiento de Google Analytics desde Google Tag Manager.",
      "_ga-cookie-text":
        "Esta cookie es instalada por Google Analytics. La cookie se utiliza para calcular los datos de los visitantes, de la sesión y de la campaña y para hacer un seguimiento del uso del sitio para el informe de análisis del sitio. La cookie almacena la información de forma anónima y asigna un número generado aleatoriamente para identificar a los visitantes únicos.",
      "_gid-cookie-text":
        "Esta cookie es instalada por Google Analytics. La cookie se utiliza para almacenar información sobre cómo los visitantes usan un sitio web y ayuda a crear un informe analítico de cómo está funcionando el sitio web. Los datos recopilados, incluidos el número de visitantes, la fuente de donde provienen y las páginas, aparecieron de forma anónima.",
      "uuid-cookie-text":
        "Esta cookie se utiliza para optimizar la relevancia de los anuncios mediante la recopilación de datos de los visitantes de varios sitios web; este intercambio de datos de los visitantes suele ser proporcionado por un centro de datos de terceros o un intercambio de anuncios.",
      "sib_cuid-cookie-text":
        "Permiten mostrar ofertas relevantes para los intereses del visitante. Muestran anuncios personalizados de forma anónima.Recoge información sobre la navegación del usuario y sus preferencias en el sitio web, que se utiliza para enviar posibles boletines informativos basados en esta información.",
      "1-year": "1 año",
      "1-minute": "1 minuto",
      "2-years": "2 años",
      "1-day": "1 día",
      "13-months": "13 meses",
      "30-minutes": "30 minutos",
      "30-years": "30 años",
      enabled: "Habilitadas",
      disabled: "Deshabilitadas",
      analytics: "Analíticas",
      duration: "Duración",
      description: "Descripción",
      "_pk_id-cookie-text":
        "Se utiliza para almacenar algunos detalles sobre el usuario, como la identificación única del visitante.",
      "_pk_ses-cookie-text":
        "Se usa para almacenar temporalmente los datos de la visita.",
      "mtm_cookie_consent-cookie-text":
        "Se utiliza para recordar que el usuario ha dado su consentimiento para el almacenamiento y uso de las cookies de Matomo.",
      "preferences-cookie-text":
        "Las cookies de preferencias permiten a un sitio web recordar información que cambia el comportamiento o el aspecto del sitio web, como su idioma preferido o la región en la que se encuentra.",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en", // use en if detected lng is not available
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
