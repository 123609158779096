import { useEffect } from "react";
import { Link } from "wouter";
import logo from "../src/assets/images/landing/Logoayuntamiento.png";
import HeaderCookiesPrivacy from "./HeaderCookiesPrivacy";
const PrivacyPageSpanish = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <HeaderCookiesPrivacy />
      <div className="px-4 py-8 bg-white">
        <h1 className="text-cookiesBlue uppercase text-xl font-latoBold">
          Política de privacidad
        </h1>
        <div className="py-8 font-gatterSansRegular text-sm">
          <p>
            La presente Política de Privacidad ha sido desarrollada teniendo en
            cuenta lo establecido en la normativa vigente en materia de
            protección de datos: el Reglamento 2016/679 del Parlamento Europeo y
            del consejo del 27 de abril de 2016 relativo a la protección de las
            personas físicas en lo que respecta al tratamiento de datos
            personales y a la circulación de estos datos, (en adelante RGPD); y
            la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
            Personales y garantía de los derechos digitales (en adelante
            LOPDGDD).
            <br />
            Esta Política de Privacidad tiene por objeto poner en conocimiento
            de las personas titulares de los datos personales, respecto de los
            cuales se está recabando información, los aspectos específicos
            relativos al tratamiento sus datos, entre otras cosas, las
            finalidades de los tratamientos, los datos de contacto para ejercer
            los derechos que le asisten, los plazos de conservación de la
            información y las medidas de seguridad entre otras cosas.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            RESPONSABLE DEL TRATAMIENTO
          </h2>
          <p>
            Sociedad Municipal Zaragoza Cultural, S.A.U (ZARAGOZA CULTURAL, en
            adelante)
            <br />
            CIF: A50377787
            <br />
            Dirección: C/ Torrenueva 25, 2ª planta – 50003 Zaragoza
            <br />
            E-mail: info@zaragozacultural.com
            <br />
            Contacto DPO: dpo@zaragozacultural.com
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">TRATAMIENTOS DE DATOS</h2>
          <p>
            Los datos personales que se soliciten, en su caso, consistirán
            únicamente en aquellos estrictamente imprescindibles para
            identificar y atender la solicitud realizada por la persona titular
            de los mismos, en adelante el/la interesado/a. Dicha información
            será tratada de forma leal, lícita y transparente en relación con
            el/la interesado/a. Por otra parte, los datos personales serán
            recogidos para finalidades determinadas explícitas y legítimas, no
            siendo tratados ulteriormente de manera incompatible con dichos
            fines.
            <br />
            Los datos recogidos de cada interesado/a serán adecuados,
            pertinentes y no excesivos en relación a las finalidades
            correspondientes para cada caso, y serán actualizados siempre que
            sea necesario.
            <br />
            La persona titular de los datos será informada, con carácter previo
            a la recogida de sus datos, de los extremos generales regulados en
            esta política a fin de que pueda prestar el consentimiento expreso,
            preciso e inequívoco para el tratamiento de sus datos, si éste fuese
            necesario y conforme a los siguientes aspectos.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            FINALIDADES DEL TRATAMIENTO
          </h2>
          <p>
            Las finalidades explícitas para las cuales se llevan a cabo cada uno
            de los tratamientos vienen recogidas en las cláusulas informativas
            incorporadas en cada una de las vías de toma de datos (formularios
            web, formularios en papel, contratos, carteles, etc.).
            <br />
            No obstante, en general la recogida y tratamiento de los datos tiene
            como finalidad:
            <br />
            - El mantenimiento de la relación contractual establecida entre el
            titular de los datos y ZARAGOZA CULTURAL.
            <br />
            - La ejecución, el mantenimiento y gestión de cualesquiera
            relaciones que pudieran mantener ZARAGOZA CULTURAL y la persona
            titular de los datos: comerciales, administrativas, contables,
            laborales, de marketing, y de cualquier otra prestación de servicios
            que fuese solicitada a ZARAGOZA CULTURAL por la persona titular de
            los datos.
            <br />
            - Facilitar el acceso a los distintos contenidos de la web.
            <br />
            - Dar respuesta a las solicitudes de información y/o contacto.
            <br />
            - Gestionar su suscripción a la newsletter y proceder al envío
            periódico de la misma para las personas que se hayan suscrito.
            <br />
            - Gestionar la ofrenda virtual en la que va a participar y poder
            publicar dicha ofrenda en las pantallas ubicadas en la Plaza del
            Pilar de Zaragoza, si ésta se publica, asimismo, en la red social
            Twitter.
            <br />
            - El envío de información publicitaria y promocional, relativa a los
            propios servicios de ZARAGOZA CULTURAL, siempre que haya prestado su
            consentimiento para ello o bien exista una relación
            comercial/contractual previa y se refiera al envío de comunicaciones
            comerciales referentes a productos o servicios similares a los que
            inicialmente fueron objeto de contratación con el cliente (artículo
            21.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad
            de la Información y Comercio Electrónico).
            <br />
            En ningún caso serán utilizados para otras finalidades distintas de
            aquéllas para las que han sido recabados.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">LEGITIMACIÓN</h2>
          <p>
            Por regla general, previo al tratamiento de los datos personales,
            ZARAGOZA CULTURAL obtiene consentimiento inequívoco de la personal
            titular de los mismos, mediante la incorporación de cláusulas de
            consentimiento informado en los diferentes sistemas de recogida de
            información.
            <br />
            No obstante, en caso de que no se requiera el consentimiento de la
            persona interesada, la base legitimadora del tratamiento en la cual
            se ampara ZARAGOZA CULTURAL es la ejecución de la relación
            contractual establecida, nuestro interés legítimo en el desarrollo
            de las actividades que nos son propias, en el caso de la promoción y
            prospección comerciales, el desarrollo de una misión realizada en
            interés público o el cumplimiento de una ley que autorice o exija el
            tratamiento de los datos del interesado.
            <br />
            Todas las cláusulas de consentimiento informado expresan cuál es la
            base legitimadora del tratamiento de los datos personales.
            <br />
            Si se ha solicitado su consentimiento para alguno de los
            tratamientos que vamos a llevar a cabo (como puede ser es el envío
            de información sobre las actividades de ZARAGOZA CULTURAL) le
            informamos que puede retirarlo en cualquier momento.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">DESTINATARIOS</h2>
          <p>
            Como regla general, ZARAGOZA CULTURAL no informará sobre sus datos
            personales a ningún tercero, salvo que la persona Titular haya
            otorgado su consentimiento a dichos efectos, cuando la cesión esté
            autorizada por alguna Ley o en los casos de entidades con los que
            mantengamos relación y en virtud de contratos de encargados del
            tratamiento celebrados con las mismas.
            <br />
            En este sentido, y tal como ha quedado expresado en el apartado
            “Finalidades del tratamiento”, las ofrendas virtuales que, asimismo,
            sean compartidas en Twitter podrán ser publicadas/mostradas en las
            pantallas ubicadas en la Plaza del Pilar de Zaragoza.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            TRANSFERENCIAS INTERNACIONALES DE DATOS
          </h2>
          <p>
            No está prevista la realización de transferencias internacionales de
            datos.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">PLAZOS DE CONSERVACIÓN</h2>
          <p>
            La información recabada del interesado/a será conservada mientras
            sea necesaria para cumplir con la finalidad para la cual fueron
            recabados los datos personales, de forma que, una vez cumplida la
            finalidad los datos serán cancelados. Dicha cancelación dará lugar
            al bloqueo de los datos conservándose únicamente a disposición de
            las AAPP, Jueces y Tribunales, para atender las posibles
            responsabilidades nacidas del tratamiento, durante el plazo de
            prescripción de éstas, cumplido el citado plazo se procederá a la
            destrucción de la información.
            <br />A título informativo, a continuación se recogen los plazos
            legales de conservación de la información en relación a diferentes
            materias:
          </p>{" "}
          <img src="../tabla_politica_ES.png" className="my-4" />
          <h2 className="mt-6 mb-2 font-latoBold">DATOS DE NAVEGACIÓN</h2>
          <p>
            En relación a los datos de navegación que se puedan tratar a través
            del sitio web, en caso de que se recojan datos sometidos a la
            normativa, se recomienda consultar la{" "}
            <a
              className="text-cookiesBlue underline font-bold"
              href="https://ofrendadeflores.com/policies/pc/es/cookies.html"
              target="_blank"
            >
              Política de Cookies
            </a>{" "}
            publicada en nuestro sitio web.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            DERECHOS DE LOS INTERESADOS
          </h2>
          <p>
            Cualquier persona tiene derecho a obtener confirmación sobre si
            ZARAGOZA CULTURAL está tratando datos personales que les conciernan,
            o no.
            <br />
            Las personas interesadas tienen derecho a acceder a sus datos
            personales, así como a solicitar la rectificación de los datos
            inexactos o, en su caso, solicitar su supresión cuando, entre otros
            motivos, los datos ya no sean necesarios para los fines que fueron
            recogidos.
            <br />
            En determinadas circunstancias, las personas interesadas podrán
            solicitar la limitación del tratamiento de sus datos, en cuyo caso
            únicamente los conservaremos para el ejercicio o la defensa de
            reclamaciones.
            <br />
            En determinadas circunstancias y por motivos relacionados con su
            situación particular, las personas interesadas podrán oponerse al
            tratamiento de sus datos. En este caso, ZARAGOZA CULTURAL dejará de
            tratar los datos, salvo por motivos legítimos imperiosos, o el
            ejercicio o la defensa de posibles reclamaciones.
            <br />
            Las personas interesadas podrán ejercitar los derechos indicados,
            dirigiéndose a ZARAGOZA CULTURAL, mediante escrito dirigido a la
            Unidad de Comunicación y Patrocinio de la Sociedad Municipal
            Zaragoza Cultural (sita en Torreón Fortea, C/ Torrenueva, 25 de
            Zaragoza) o a la dirección de correo electrónico
            info@zaragozacultural.com, adjuntando en todo caso, fotocopia del
            D.N.I o documento equivalente válido en derecho que permita
            acreditar su identidad.
            <br />
            Igualmente tiene la posibilidad de presentar una reclamación ante la
            Agencia Española de Protección de Datos (aepd.es) en el caso de que
            considere que el tratamiento de sus datos no está siendo adecuado.
          </p>
          <p className="mt-4">
            Más información sobre ejercicio de derechos{" "}
            <a
              className="text-cookiesBlue underline font-bold"
              href="https://www.zaragoza.es/sede/servicio/tramite/23680"
              target="_blank"
            >
              AQUÍ
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPageSpanish;
