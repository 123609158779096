import { useState, useLayoutEffect } from "react";
import landingQrOpen from "./assets/images/landing2023/landingQrOpen.jpg";
import landingQrClose from "./assets/images/landing2023/landingQrClose.jpg";
import background from "./assets/images/landing2023/bgPc.jpg";
import qr from "./assets/images/landing2023/qrlanding.png";
import { useTranslation } from "react-i18next";
import { APP_ROUTES } from "./utils/utils";
import i18n from "./i18n";

const LandingPC = () => {
  const { t } = useTranslation();
  const [minified, setMinified] = useState<boolean>(false);
  const [size, setSize] = useState<number>(window.innerHeight / 17.5);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setSize(window.innerHeight / 17.5);
    });
  }, []);

  return (
    <div
      className="w-full h-full">
    <img className="absolute w-full bottom-0 " src={background} />
      <div
        className="w-full h-[95%] flex justify-center items-center relative"
        data-drag-scroll-enabled="true"
      >
        <iframe
          scrolling="yes"
          style={{
            border: "13px solid #004A5F",
            borderRadius: "50px",
            backgroundColor: "#004A5F",
          }}
          src="./pc"
          title="Ofrenda Iframe"
          className="overflow-auto"
          seamless
          width={9 * size}
          height={16 * size}
        ></iframe>

        <button
          className="absolute flex flex-col gap-4 max-w-[25vw] h-auto right-5 bottom-0 bg-white justify-end text-[#9A2C6A] text-center items-center"
          onClick={() => {
            setMinified(!minified);
          }}
        >
          {minified ? (
            <img src={landingQrClose} />
          ) : (
            <img src={landingQrOpen} />
          )}
        </button>
      </div>
      <div className="absolute w-full h-[5%] bg-white flex items-center justify-center pl-10 flex-row gap-3 text-[#9A2C6A] font-latoRegular text-sm">
        <div>
          <a
            onClick={() => i18n.changeLanguage("es")}
            href={APP_ROUTES.PRIVACIDAD}
            className="font-lato"
          >
            {"POLÍTICA DE PRIVACIDAD /"}
          </a>
          <a
            onClick={() => i18n.changeLanguage("en")}
            href={APP_ROUTES.PRIVACIDAD}
            className="font-lato"
          >
            {" PRIVACY POLICY"}
          </a>
        </div>
        <span>-</span>
        <div>
          <a
            onClick={() => i18n.changeLanguage("es")}
            href={APP_ROUTES.COOKIES}
            className="font-lato"
          >
            {"POLÍTICA DE COOKIES /"}
          </a>
          <a
            onClick={() => i18n.changeLanguage("en")}
            href={APP_ROUTES.COOKIES}
            className="font-lato"
          >
            {" COOKIES POLICY"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingPC;
