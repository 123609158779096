import { useEffect } from "react";
import { Link } from "wouter";
import logo from "../src/assets/images/landing/Logoayuntamiento.png";
import HeaderCookiesPrivacy from "./HeaderCookiesPrivacy";
const PrivacyPageSpanish = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <HeaderCookiesPrivacy />
      <div className="px-4 py-8 bg-white">
        <h1 className="text-cookiesBlue uppercase text-xl font-latoBold">
          Privacy policy
        </h1>
        <div className="py-8 font-gatterSansRegular text-sm">
          <p>
            This Privacy Policy has been developed taking into account the
            provisions of current regulations on data protection: Regulation
            2016/679 of the European Parliament and of the Council of April 27,
            2016 regarding the protection of natural persons in with regard to
            the processing of personal data and the circulation of these data,
            (hereinafter RGPD); and Organic Law 3/2018, of December 5, on the
            Protection of Personal Data and guarantee of digital rights
            (hereinafter LOPDGDD).
            <br />
            The purpose of this Privacy Policy is to inform the owners of the
            personal data, regarding which information is being collected, the
            specific aspects related to the processing of their data, among
            other things, the purposes of the treatments, the data of contact to
            exercise the rights that assist you, the periods of conservation of
            the information and the security measures among other things.
          </p>

          <h2 className="mt-6 mb-2 font-latoBold">RESPONSIBLE OF TREATMENT</h2>
          <p>
            Sociedad Municipal Zaragoza Cultural, SAU (ZARAGOZA CULTURAL,
            hereinafter
            <br />
            CIF: A50377787
            <br />
            Address: C / Torrenueva 25, 2ª planta - 50003 Zaragoza
            <br />
            E-mail: info@zaragozacultural.com
            <br />
            DPO contact: dpo@zaragozacultural.com
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">DATA PROCESSING</h2>
          <p>
            The personal data that is requested, where appropriate, will consist
            only of those strictly essential to identify and attend the request
            made by the person who owns them, hereinafter the interested party.
            Said information will be treated in a fair, lawful and transparent
            manner in relation to the interested party. On the other hand,
            personal data will be collected for specific explicit and legitimate
            purposes, not being further processed in a manner incompatible with
            said purposes.
            <br />
            The data collected from each interested party will be adequate,
            pertinent and not excessive in relation to the corresponding
            purposes for each case, and will be updated whenever necessary.
            <br />
            The person who owns the data will be informed, prior to the
            collection of their data, of the general points regulated in this
            policy so that they can give express, precise and unequivocal
            consent for the processing of their data, if this were necessary and
            in accordance with the following aspects.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">PURPOSES OF THE TREATMENT</h2>
          <p>
            The explicit purposes for which each of the treatments are carried
            out are included in the information clauses incorporated in each of
            the data collection channels (web forms, paper forms, contracts,
            posters, etc.).
            <br />
            However, in general the collection and processing of data is
            intended to:
            <br />
            - The maintenance of the contractual relationship established
            between the owner of the data and ZARAGOZA CULTURAL.
            <br />
            - The execution, maintenance and management of any relationships
            that ZARAGOZA CULTURAL and the person who owns the data may
            maintain: commercial, administrative, accounting, labor, marketing,
            and any other provision of services that was requested from ZARAGOZA
            CULTURAL by the person who owns the data.
            <br />
            - Facilitate access to the different contents of the web.
            <br />
            - Respond to requests for information and / or contact.
            <br />
            - Manage your subscription to the newsletter and proceed to send it
            periodically to the people who have subscribed.
            <br />
            - Manage the virtual offering in which you are going to participate
            and be able to publish said offering on the screens located in the
            Plaza del Pilar in Zaragoza, if it is also published on the social
            network Twitter.
            <br />
            - Manage the virtual offering in which you are going to participate
            and be able to publish said offering on the screens located in the
            Plaza del Pilar in Zaragoza, if it is also published on the social
            network Twitter.
            <br />
            In no case will they be used for purposes other than those for which
            they have been collected.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">LEGITIMATION</h2>
          <p>
            As a general rule, prior to the processing of personal data,
            ZARAGOZA CULTURAL obtains unequivocal consent from the personal
            owner thereof, by incorporating informed consent clauses in the
            different information collection systems.
            <br />
            However, if the consent of the interested person is not required,
            the legitimizing basis of the treatment on which ZARAGOZA CULTURAL
            relies on is the execution of the established contractual
            relationship, our legitimate interest in the development of the
            activities that are not us own, in the case of commercial promotion
            and prospecting, the development of a mission carried out in the
            public interest or compliance with a law that authorizes or requires
            the processing of the data of the interested party.
            <br />
            All the clauses of informed consent express what is the legitimate
            basis for the processing of personal data.
            <br />
            If your consent has been requested for any of the treatments that we
            are going to carry out (such as the sending of information about the
            activities of ZARAGOZA CULTURAL) we inform you that you can withdraw
            it at any time.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">RECIPIENTS</h2>
          <p>
            As a general rule, ZARAGOZA CULTURAL will not inform any third party
            about your personal data, unless the Owner has given their consent
            for such purposes, when the transfer is authorized by any Law or in
            the cases of entities with which we maintain a relationship and in
            by virtue of contracts of those in charge of the treatment
            celebrated with them.
            <br />
            In this sense, and as it has been expressed in the section “Purposes
            of the treatment”, the virtual offerings that are also shared on
            Twitter may be published / displayed on the screens located in the
            Plaza del Pilar in Zaragoza.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            INTERNATIONAL DATA TRANSFERS
          </h2>
          <p>International data transfers are not planned.</p>
          <h2 className="mt-6 mb-2 font-latoBold">CONSERVATION PERIODS</h2>
          <p>
            The information collected from the interested party will be kept as
            long as it is necessary to fulfill the purpose for which the
            personal data were collected, so that, once the purpose is
            fulfilled, the data will be canceled. Said cancellation will lead to
            the blocking of the data, being kept only at the disposal of the
            Public Administrations, Judges and Courts, to attend to the possible
            responsibilities arising from the treatment, during the limitation
            period of these, once the aforementioned period has elapsed, the
            information will be destroyed.
            <br />
            For information purposes, the legal terms of conservation of
            information in relation to different matters are set out below:
          </p>
          <img src="../tabla_politica_EN.png" className="my-4" />
          <h2 className="mt-6 mb-2 font-latoBold">NAVIGATION DATA</h2>
          <p>
            In relation to the navigation data that can be processed through the
            website, if data subject to the regulations is collected, it is
            recommended to consult the{" "}
            <a
              href="https://ofrendadeflores.com/policies/pc/en/cookies.html"
              target="_blank"
            >
              Cookies Policy
            </a>{" "}
            published on our website.
          </p>
          <h2 className="mt-6 mb-2 font-latoBold">
            RIGHTS OF THE INTERESTED PARTIES
          </h2>
          <p>
            Anyone has the right to obtain confirmation about whether ZARAGOZA
            CULTURAL is treating personal data that concerns them, or not.
            <br />
            Interested persons have the right to access their personal data, as
            well as to request the rectification of inaccurate data or, where
            appropriate, request its deletion when, among other reasons, the
            data is no longer necessary for the purposes for which it was
            collected.
            <br />
            In certain circumstances, interested persons may request the
            limitation of the processing of their data, in which case we will
            only keep them for the exercise or defense of claims.
            <br />
            In certain circumstances and for reasons related to their particular
            situation, interested persons may object to the processing of their
            data. In this case, ZARAGOZA CULTURAL will stop processing the data,
            except for compelling legitimate reasons, or the exercise or defense
            of possible claims.
            <br />
            Interested persons may exercise the indicated rights, by writing to
            ZARAGOZA CULTURAL, by writing to the Communication and Sponsorship
            Unit of the Zaragoza Cultural Municipal Society (located in Torreón
            Fortea, C / Torrenueva, 25 de Zaragoza) or to the email address
            info@zaragozacultural.com , attaching in any case, a photocopy of
            the DNI or equivalent document valid in law that allows to prove
            your identity.
            <br />
            You also have the possibility of filing a claim with the Spanish
            Data Protection Agency (aepd.es) in the event that you consider that
            the processing of your data is not being adequate.
          </p>
          <p className="mt-4">
            More information on the exercise of rights{" "}
            <a
              href="https://www.zaragoza.es/sede/servicio/tramite/23680"
              className="text-cookiesBlue underline font-bold"
            >
              HERE
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPageSpanish;
