import create, { StoreApi } from "zustand";

interface StateInterface {
  myIP: string;
  locationSpain: boolean;
  setMyIP: (value: string) => void;
  setlocationSpain: (value: boolean) => void;
}
const useStore = create<StateInterface>()((set) => ({
  myIP: "",
  locationSpain: true,
  setMyIP: (value: string) => set((state) => ({ myIP: value })),
  setlocationSpain: (value: boolean) =>
    set((state) => ({ locationSpain: value })),
}));

export default useStore;
